/**
 * @file   AppointmentsList.tsx
 * @brief  Enrollee Edit component
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '../css/images/editIcon.png';
import SearchIcon from '../css/images/search-icon.png';
import ClearIcon from '../css/images/clearIcon.png';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import * as alerts from '../../messages';

const AppointmentsList = ({ ...props }) => {
  var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [show, setShow] = useState(false);
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
  const [appoitmentId, setAppointmentId] = useState(0);
  useEffect(() => {
    var sortedArry: any = sortByDate(props.appointmentsList);
    setAppointmentsList(sortedArry);
  }, [props.appointmentsList]);

  const sortByDate = (arr: any) => {
    arr.sort(function(x: any, y: any) {
      return x.DateTime - y.DateTime;
    });
    return arr;
  };

  const handleClick = (e: any, id: any) => {
    var filtereddata = appointmentsList.filter((item: any) => item.appointmentID === id);
    if (filtereddata.length > 0) {
      props.setAppointment(filtereddata[0]);
    }
  };

  const handleClear = () => {
    setSearchKey('');
    props.fileterByEntrolle('');
  };
  const handleTextChange = (event: any) => {
    setSearchKey(event.target.value);
    if (event.target.value.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
    props.fileterByEntrolle(event.target.value);
  };
  const handleDeleteClick = (e: any, id: any) => {
    e.stopPropagation();
    setDeleteConfirmShow(true);
    setAppointmentId(id);
  };
  const handleEditClick = (e: any, id: any) => {
    e.stopPropagation();
    props.editAppoinment(id);
  };
  const handleClose = () => {
    setDeleteConfirmShow(false);
    setAppointmentId(0);
  };
  const handleYes = () => {
    props.deleteAppoinment(appoitmentId);
    setDeleteConfirmShow(false);
  };
  const handleCancel = () => {
    setDeleteConfirmShow(false);
    setAppointmentId(0);
  };

  return (
    <div className="col-xl-6 col-lg-12 col-md-12 ml-auto">
      {props.AppointmentNav == 'Sub' && (
        <div className="searchBar d-flex">
          <img src={SearchIcon} className="mr-2 mt-1 ml-2" style={{ width: '26px', height: '26px' }} />
          <TextField
            placeholder=" Search by enrollee ID or enrollee name"
            value={searchKey}
            onChange={e => handleTextChange(e)}
          />
          <img
            src={ClearIcon}
            style={{ display: show ? 'block' : 'none', width: '20px', height: '20px', cursor: 'pointer' }}
            className="mr-2 mt-2 ml-2"
            onClick={() => handleClear()}
          />
        </div>
      )}
      {appointmentsList?.length == 0 && (
        <div className="d-flex" style={{ height: '400px' }}>
          <div className="text-center message mt-5 mb-5 align-self-center w-100">No appointments to display.</div>
        </div>
      )}
      {appointmentsList?.map((appointment: any, index) => (
        <div className="appointmentWrap mb-2" key={index} onClick={e => handleClick(e, appointment.appointmentID)}>
          <div className="appointmentTop d-flex flex-wrap">
            <div className="dateTime p-3">
              <h5>{days[moment.unix(appointment.DateTime).day()]}</h5>
              <h5>
                {moment.unix(appointment.DateTime).format('MMM')} {moment.unix(appointment.DateTime).format('DD')}
              </h5>
              {/* <h4></h4> */}
              {moment.unix(appointment.DateTime).format('h:mm A')} To{' '}
              {moment
                .unix(appointment.DateTime)
                .add(appointment.duration, 'minutes')
                .format('h:mm A')}
            </div>
            <div className="p-3 pt-4 dateTimeRight">
              {props.AppointmentNav == 'Sub' && <p>{appointment.enrolleeName}</p>}
              <p> {appointment.Description}</p>
            </div>
          </div>
          <div className="appointmentBtm text-right">
            {moment.unix(appointment.DateTime) > moment.utc() && (
              <Button onClick={e => handleEditClick(e, appointment.appointmentID)}>
                <img src={EditIcon} className="pr-1 mb-1 mr-1" />
                {'Edit'}
              </Button>
            )}

            <Button onClick={e => handleDeleteClick(e, appointment.appointmentID)}>
              <DeleteOutlineOutlinedIcon /> {'Delete'}
            </Button>
          </div>
        </div>
      ))}
      <Modal
        show={deleteConfirmShow}
        onHide={handleClose}
        animation={false}
        className="enrollee-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{alerts.CONFIRM_DELETE_APPOINTMENT}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="outline-primary" className="btn-outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" className="btn-primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AppointmentsList;
