/**
 * @file   NoteModal.tsx
 * @brief  Note modal componenent
 * @date   June , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */
import * as React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';
import MultipleSelect from '../common/MultipleSelect';
import * as alerts from '../../messages';
import { logout } from '../../functions';

type MyProps = { modalClose(status: number): void; ChannelID?: any; CallID?: any; enrolleeId?: any };
type MyState = {
  showStatus: boolean;
  note: string;
  noteValidation: boolean;
  contactValidation: boolean;
  connectedStatus: Number;
  validationMessage: string;
  popup: boolean;
  pageLoading: Boolean;
  noteAdded: Boolean;
  noteAlreadyAdded: Boolean;
  contactTypes: Array<string | null> | string | null;
  contactTypesSelected: Array<string | null> | string | null;
  typesfiltered: Array<object | null> | null;
  sessionOut: Boolean;
};

export class NoteModel extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showStatus: false,
      note: '',
      noteValidation: false,
      contactValidation: false,
      validationMessage: '',
      connectedStatus: 0,
      popup: false,
      pageLoading: false,
      noteAdded: false,
      noteAlreadyAdded: false,
      contactTypes: [],
      contactTypesSelected: [],
      typesfiltered: [],
      sessionOut: false,
    };
  }

  componentDidMount() {
    let types = JSON.parse(localStorage.getItem('ContactTypes') || '[]');
    if (this.props.CallID) {
      types = types.filter((type: any) => type.ForChat == 'CHECKED');
    }
    this.setState({ typesfiltered: types });
  }

  // Handles modal close
  handleClose = () => {
    if (this.props.CallID) return false;
    else this.props.modalClose(2);
  };

  // Handles modal close
  handleYes = () => {
    this.props.modalClose(2);
  };

  //Handle session out ok
  handleSessionOut = () => {
    this.props.modalClose(2);
    logout();
  };

  handleShow = () => {};

  closeButton = () => {
    if (this.props.CallID) return false;
    else return true;
  };
  //Updaing conatct type state
  handleChange = (event: any) => {
    let convrted = Array.prototype.map.call(event, s => ' ' + s.value).toString();
    this.setState({ contactTypesSelected: event });
    this.setState({ contactTypes: convrted });
    this.setState({ note: convrted == '' ? '' : convrted + ' - ' });
  };
  //Updaing note state
  handleChangeNote = (event: any) => {
    this.setState({ note: event.target.value });
  };

  // Modal render function
  render() {
    const onSubmit = (e: any) => {
      e.preventDefault();

      if (this.state.typesfiltered && this.state.typesfiltered.length > 0 && this.state.contactTypes == '') {
        this.setState({ contactValidation: true, note: '' });
        this.setState({ validationMessage: ' Select Contact Type.' });
        return;
      }
      this.setState({ validationMessage: '' });

      if (this.state.note == '') {
        this.setState({ noteValidation: true });
        this.setState({ validationMessage: 'Enter Notes.' });
        return;
      }

      this.setState({ noteValidation: false, contactValidation: false, validationMessage: '' });
      this.setState({ pageLoading: true });
      let session_enrollee_id = this.props.enrolleeId
        ? this.props.enrolleeId
        : sessionStorage.getItem('session_enrollee_id');
      let officer_id = localStorage.getItem('officer_id');
      let session_id = localStorage.getItem('user');

      let chatParms = '';
      if (this.props.CallID) {
        chatParms = '&CallID=' + this.props.CallID + '&room_id=' + this.props.ChannelID;
      }

      let noteVal = this.state.note.replace(/&/g, 'and');
      noteVal = this.state.note.replace('\n', '<br/>');

      const requestOptions = {
        body:
          'session_id=' +
          session_id +
          '&officer_id=' +
          officer_id +
          '&enrollee_id=' +
          session_enrollee_id +
          '&note=' +
          noteVal +
          '&contact_type=' +
          this.state.contactTypes +
          chatParms,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
      };
      return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/add_note', requestOptions)
        .then(res =>
          res.json().then(
            data => {
              if (data.response.status == 'Successful') {
                sessionStorage.setItem('note_added', '1');
                this.setState({ pageLoading: false });
                this.setState({ noteAdded: true });
              } else if (data.response.errorCode == 5) {
                this.setState({ pageLoading: false });
                this.setState({ sessionOut: true });
              }
            },
            error => {
              this.props.modalClose(2);
            }
          )
        )
        .catch(error => {
          this.props.modalClose(2);
        });
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div>
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
          onHide={this.handleClose}
        >
          <Form onSubmit={onSubmit}>
            {this.state.noteAdded == false && this.state.sessionOut == false ? (
              <Modal.Header closeButton={this.closeButton()} style={{ paddingBottom: '0' }}>
                <Modal.Title style={{ width: '100%' }}>Add your notes here</Modal.Title>
              </Modal.Header>
            ) : null}
            <Modal.Body>
              {this.state.noteAdded == true || this.state.noteAlreadyAdded == true ? (
                <h6 style={{ textAlign: 'center', color: 'black' }}>
                  {this.state.noteAdded == true && this.state.noteAlreadyAdded == false
                    ? 'Notes have been added successfully.'
                    : null}
                </h6>
              ) : null}
              {this.state.sessionOut == true ? (
                <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
              ) : null}
              {this.state.pageLoading == false &&
              this.state.noteAdded == false &&
              this.state.sessionOut == false &&
              this.state.typesfiltered &&
              this.state.typesfiltered.length > 0 ? (
                <div>
                  {
                    <MultipleSelect
                      handleChange={this.handleChange}
                      typesfiltered={this.state.typesfiltered}
                      contactTypes={this.state.contactTypesSelected}
                    />
                  }
                </div>
              ) : null}

              {this.state.pageLoading == false && this.state.noteAdded == false && this.state.sessionOut == false ? (
                <TextField
                  id="outlined-multiline-static"
                  className="pop-textarea w-100"
                  multiline
                  minRows={4} maxRows={4}                  value={this.state.note}
                  variant="outlined"
                  onChange={this.handleChangeNote}
                />
              ) : null}
              {this.state.pageLoading == false ? (
                this.state.noteValidation == true || this.state.contactValidation == true ? (
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ color: 'red', marginTop: '5px', marginBottom: '0' }}>
                      {this.state.validationMessage}
                    </label>
                  </div>
                ) : (
                  ''
                )
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Loader type="Oval" color="#55ff00" height={60} width={60} />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer style={{ paddingTop: '0' }}>
              {this.state.pageLoading == false && this.state.noteAdded == false && this.state.sessionOut == false ? (
                <Button variant="primary" className="btn-primary" type="submit">
                  Submit
                </Button>
              ) : null}

              {this.state.noteAdded == true ? (
                <Button variant="primary" className="btn-primary" onClick={this.handleYes}>
                  OK
                </Button>
              ) : null}
              {this.state.sessionOut == true ? (
                <Button variant="primary" className="btn-primary" onClick={this.handleSessionOut}>
                  OK
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
