/**
 * @file   MultipleSelect.tsx
 * @brief  Multiple select list component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';

interface Props {
  handleChange(event: any): void;
  typesfiltered: any;
  contactTypes: any;
}
export default function MultipleSelect(props: Props) {
  const meg: any = new Object({ allItemsAreSelected: 'All types are selected.' });

  let options = props.typesfiltered.map((data: any) => {
    return new Object({
      label: data.Type,
      value: data.Type,
    });
  });
  return (
    <div className="mb-4">
      <h6 className="text-dark">Contact Type</h6>
      <MultiSelect
        options={options}
        value={props.contactTypes}
        hasSelectAll={false}
        onChange={props.handleChange}
        labelledBy="Select"
        disableSearch={true}
        overrideStrings={meg}
      />
    </div>
  );
}
