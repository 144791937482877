// Get random numbers
export function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('username');
  localStorage.removeItem('room');
  localStorage.removeItem('access_token');
  localStorage.removeItem('twilioAccount');
  localStorage.removeItem('twilioAPIKey');
  localStorage.removeItem('twilioAPISecret');
  localStorage.removeItem('officer_id');
  localStorage.removeItem('is_voice_mail');
  localStorage.removeItem('enrollees');
  localStorage.removeItem('searchkey');
  localStorage.removeItem('is_appointment');
  window.location.href = '/login';
}

export function redirectToList() {
  window.location.href = '/EnrolleeList';
}
