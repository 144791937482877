import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import List from './components/EnrolleeList/List';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import PushNotification from './components/PushNotification/PushNotification';
// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.

const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'presentation',
      //dominantSpeakerPriority: 'standard',
      dominantSpeakerPriority: 'high',
      contentPreferencesMode: 'auto',
      clientTrackSwitchOffControl: 'auto',
    },
  },
  dominantSpeaker: true,
  // maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  //preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  preferredVideoCodecs: 'auto',
};

const VideoApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};
/*const registerServiceWorker = async () => {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      console.log('Un Register..', registration);
      registration.unregister();
    }
  });
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        scope: '/',
      });
      if (registration.installing) {
        console.log('Service worker installing');
      } else if (registration.waiting) {
        console.log('Service worker installed');
      } else if (registration.active) {
        console.log('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};*/
//registerServiceWorker();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <Route exact={true} path="/Login" component={Login} />
          <Route exact={true} path="/EnrolleeList" component={List} />
          <Route exact={true} path="/EnrolleeList/:SessionToken/:UserName/:OfficerID" component={List} />

          <PrivateRoute exact path="/startchat">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <Redirect to="/Login" />
        </Switch>
        <PushNotification />
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
