/**
 * @file   EnrollEdit.tsx
 * @brief  Enrollee Edit component
 * @date   June , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React, { useState, useEffect } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from 'react-bootstrap';
import loadingimg from '../css/images/loading.gif';
import { Modal } from 'react-bootstrap';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField } from '@material-ui/core';
import * as alerts from '../../messages';
import { logout } from '../../functions';

interface Props {
  handleMenu(type: string): void;
  handleBack(type: number): void;
  validateSession(): void;
}
export default function ScheduleUpdate(props: Props) {
  const [disabled, setDisabled] = useState<boolean>(
    localStorage.getItem('phone_type') == 'LandLine' || localStorage.getItem('phone_type') == 'Non_Smart_Phone'
  );
  const [phone, setPhone] = useState<string | null>(localStorage.getItem('phone_type'));
  const [lang, setLang] = useState<string | null>(localStorage.getItem('language'));
  const [hearing, setHearing] = useState<string | null>(
    localStorage.getItem('phone_type') == 'Mobile'
      ? localStorage.getItem('hearing_impaired') == ''
        ? 'No'
        : 'CHECKED'
      : ''
  );
  const [phonenumber, setPhonenumber] = useState<any>(localStorage.getItem('phone_number'));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sessionout, setSessionOut] = useState<boolean>(false);
  const [nonMobileConfirmShow, setNonMobileConfirmShow] = useState(false);
  const [nonMobileConfirmShowYes, setNonMobileConfirmShowYes] = useState(false);

  //Mobile/Landline validation handling here
  const handleChange = (event: any) => {
    setPhone(event.target.value);
    if (event.target.value == 'Land Line' || event.target.value == 'Non_Smart_Phone') {
      setDisabled(true);
      setHearing('');
    } else {
      setDisabled(false);
      setHearing('CHECKED');
    }
    setPhonenumber('');
  };
  //Handle session out ok
  const handleSessionOut = () => {
    logout();
  };

  //Form submit handling here
  const formSubmit = (nochecking = false) => {
    if (validatePhoneNumber(phonenumber)) {
      return;
    }

    if (!nochecking && phone === 'Non_Smart_Phone' && !nonMobileConfirmShowYes) {
      setNonMobileConfirmShow(true);
      return;
    }
    setLoading(true);

    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    let hearing_impaired = phone == 'Mobile' ? (hearing == 'No' ? '' : 'CHECKED') : '';

    const requestOptions = {
      body:
        'session_id=' +
        session_id +
        '&officer_id=' +
        officer_id +
        '&enrollee_id=' +
        session_enrollee_id +
        '&phone_type=' +
        phone +
        '&phone_number=' +
        phonenumber +
        '&language=' +
        lang +
        '&hearing_impaired=' +
        hearing_impaired,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/enrollee_update', requestOptions)
      .then(res =>
        res.json().then(
          data => {
            if (data.response.status == 'Success') {
              localStorage.setItem('phone_number', phonenumber);
              localStorage.setItem('phone_type', phone || '{}');
              localStorage.setItem('language', lang || '{}');
              localStorage.setItem('hearing_impaired', hearing_impaired);
              localStorage.setItem('Schedule', JSON.stringify(data));

              setLoading(false);

              if (data.interview.enabled && !data.interview.existingSchedule && data.interview.templates.length > 0) {
                props.handleMenu('Schedule');
              } else if (
                data['drug testing'].enabled &&
                !data['drug testing'].existingSchedule &&
                data['drug testing'].templates.length > 0
              ) {
                props.handleMenu('Schedule');
              } else if (data.curfew.enabled && !data.curfew.existingSchedule && data.curfew.templates.length > 0) {
                props.handleMenu('Schedule');
              } else {
                if (
                  data.interview.templates.length == 0 &&
                  data['drug testing'].templates.length == 0 &&
                  data.curfew.templates.length == 0
                ) {
                  props.handleMenu('NoScheduletemplate');
                } else {
                  props.handleMenu('NoSchedule');
                }
              }
            } else if (data.response.errorCode == 5) {
              setSessionOut(true);
            } else {
              props.validateSession();
            }
          },
          error => {
            props.handleMenu('error');
          }
        )
      )
      .catch(error => {
        props.handleMenu('error');
      });
  };
  //Validating phone number here
  const validatePhoneNumber = (phonenumber: any) => {
    let phone = phonenumber.replace(/[^0-9]/g, '');
    return phone.length != 10;
  };

  const handleClose = () => {
    setNonMobileConfirmShow(false);
  };
  const handleYes = () => {
    setNonMobileConfirmShowYes(true);
    formSubmit(true);
  };
  const handleCancel = () => {
    setNonMobileConfirmShow(false);
  };

  return (
    <div className="enroll-main ml-lg-3 ml-sm-0 mt-4">
      <div className="d-flex">
        <KeyboardBackspaceIcon className="mr-4 pointer" onClick={() => props.handleBack(1)} />
        <h5>Enroll</h5>
      </div>
      <div className="Enroll_edit_main">
        <div className="col-lg-6 col-md-12">
          <FormControl component="fieldset">
            <FormLabel component="legend">PHONE TYPE</FormLabel>
            <RadioGroup row={true} onChange={handleChange} value={phone}>
              <FormControlLabel value="Mobile" control={<Radio />} label="Mobile With App" />
              <FormControlLabel value="Land Line" control={<Radio />} label="Landline" />
              <FormControlLabel value="Non_Smart_Phone" control={<Radio />} label="Mobile Without App" />
            </RadioGroup>
          </FormControl>
          <form>
            <FormLabel component="legend">PHONE NUMBER</FormLabel>
            <TextField
              placeholder="Enter Phone Number"
              className="w100"
              inputProps={{ 'aria-label': 'description' }}
              onChange={event => setPhonenumber(event.target.value)}
              value={phonenumber}
              error={validatePhoneNumber(phonenumber)}
              helperText={validatePhoneNumber(phonenumber) ? 'Enter a valid phone number' : ''}
            />
          </form>

          <FormControl component="fieldset">
            <FormLabel component="legend">LANGUAGE</FormLabel>
            <RadioGroup row={true} value={lang} onChange={event => setLang(event.target.value)}>
              <FormControlLabel value="English" control={<Radio />} label="English" />
              <FormControlLabel value="Spanish" control={<Radio />} label="Spanish" />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <FormLabel component="legend" className={disabled ? 'label_disabled' : ''}>
              HEARING IMPAIRED - FACE ID ONLY
            </FormLabel>
            <RadioGroup row={true} value={hearing} onChange={event => setHearing(event.target.value)}>
              <FormControlLabel value="CHECKED" control={<Radio />} label="Yes" disabled={disabled} />
              <FormControlLabel value="No" control={<Radio />} label="No" disabled={disabled} />
            </RadioGroup>
          </FormControl>
          <Button variant="primary" className="btn-block" onClick={() => formSubmit()} disabled={isLoading}>
            {isLoading ? <img src={loadingimg} /> : 'Next'}
          </Button>
        </div>
      </div>
      {sessionout && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Button variant="primary" className="btn btn-primary" onClick={handleSessionOut}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        show={nonMobileConfirmShow}
        onHide={handleClose}
        animation={false}
        className="enrollee-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{alerts.CONFIRM_MOBILE_WITHOUT_APP}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="outline-primary" className="btn-outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" className="btn-primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
