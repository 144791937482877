/**
 * @file   SMSModal.tsx
 * @brief  SMS modal componenent
 * @date   June , 2021
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import TextField from '@material-ui/core/TextField';
import { Button as Buttons } from 'react-bootstrap';
import * as alerts from '../../messages';
import { logout } from '../../functions';

type MyProps = { modalClose(status: number): void; validateSession(): void };
type MyState = {
  showStatus: boolean;
  message: string;
  messageValidation: boolean;
  connectedStatus: Number;
  validationMessage: string;
  popup: boolean;
  pageLoading: Boolean;
  messageAdded: Boolean;
  messageAlreadyAdded: Boolean;
  sessionout: Boolean;
};

export class SMSModel extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showStatus: false,
      message: '',
      messageValidation: false,
      validationMessage: '',
      connectedStatus: 0,
      popup: false,
      pageLoading: false,
      messageAdded: false,
      messageAlreadyAdded: false,
      sessionout: false,
    };
  }

  componentDidMount() {}

  // Handles modal close
  handleClose = () => {
    this.props.modalClose(3);
  };

  // Handles modal close
  handleYes = () => {
    this.props.modalClose(3);
  };

  handleShow = () => {};

  closeButton = () => {
    return true;
  };
  //Updaing message state
  handleChangeNote = (event: any) => {
    this.setState({ message: event.target.value });
  };
  //Handle session out ok
  handleSessionOut = () => {
    logout();
  };

  // Modal render function
  render() {
    const onSubmit = (e: any) => {
      e.preventDefault();

      this.setState({ validationMessage: '' });

      if (this.state.message == '') {
        this.setState({ messageValidation: true });
        this.setState({ validationMessage: 'Enter Message.' });
        return;
      }

      this.setState({ messageValidation: false, validationMessage: '' });
      this.setState({ pageLoading: true });
      let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
      let officer_id = localStorage.getItem('officer_id');
      let session_id = localStorage.getItem('user');
      let messageval = this.state.message.replace(/&/g, 'and');
      const requestOptions = {
        body:
          'session_id=' +
          session_id +
          '&officer_id=' +
          officer_id +
          '&enrollee_id=' +
          session_enrollee_id +
          '&message=' +
          messageval,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
      };
      return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/send_notification', requestOptions)
        .then(res =>
          res.json().then(
            data => {
              if (data.response.status == 'Successful') {
                sessionStorage.setItem('note_added', '1');
                this.setState({ pageLoading: false });
                this.setState({ messageAdded: true });
              } else if (data.response.errorCode == 5) {
                this.setState({ sessionout: true });
              } else {
                this.props.validateSession();
              }
            },
            error => {
              this.closeButton();
            }
          )
        )
        .catch(error => {
          this.closeButton();
        });
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div>
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
          onHide={this.handleClose}
        >
          <Form onSubmit={onSubmit}>
            {this.state.messageAdded == false ? (
              <Modal.Header closeButton={this.closeButton()} style={{ paddingBottom: '0' }}>
                <Modal.Title style={{ width: '100%' }}>SMS Notification Message</Modal.Title>
              </Modal.Header>
            ) : null}
            <Modal.Body>
              {this.state.messageAdded == true || this.state.messageAlreadyAdded == true ? (
                <h6 style={{ textAlign: 'center', color: 'black' }}>
                  {this.state.messageAdded == true && this.state.messageAlreadyAdded == false
                    ? 'Message has been sent successfully.'
                    : null}
                </h6>
              ) : null}

              {this.state.pageLoading == false && this.state.messageAdded == false ? (
                <TextField
                  id="outlined-multiline-static"
                  className="pop-textarea w-100"
                  multiline
                  minRows={4} maxRows={4}
                  value={this.state.message}
                  variant="outlined"
                  onChange={this.handleChangeNote}
                />
              ) : null}
              {this.state.pageLoading == false ? (
                this.state.messageValidation == true ? (
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ color: 'red', marginTop: '5px', marginBottom: '0' }}>
                      {this.state.validationMessage}
                    </label>
                  </div>
                ) : (
                  ''
                )
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Loader type="Oval" color="#55ff00" height={60} width={60} />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer style={{ paddingTop: '0' }}>
              {this.state.pageLoading == false && this.state.messageAdded == false ? (
                <Button variant="primary" className="btn-primary" type="submit">
                  Submit
                </Button>
              ) : null}

              {this.state.messageAdded == true ? (
                <Button variant="primary" className="btn-primary" onClick={this.handleYes}>
                  OK
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
        {this.state.sessionout && (
          <Modal
            show={true}
            animation={false}
            className="enrollee-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
          >
            <Modal.Body>
              {' '}
              <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
            </Modal.Body>
            <Modal.Footer style={{ paddingTop: '0' }}>
              <Buttons variant="primary" className="btn btn-primary" onClick={this.handleSessionOut}>
                OK
              </Buttons>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}
