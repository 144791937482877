/**
 * @file   Actions.tsx
 * @brief  Voice mail Actions
 * @date   October , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

export function GetVoicemails(activePage = 0, entrolleid = '') {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  let offset = activePage * 10;

  let body = '';
  if (entrolleid) {
    body =
      'session_id=' +
      session_id +
      '&officer_id=' +
      officer_id +
      '&exclude_unheard=true' +
      '&enrollee_id=' +
      entrolleid +
      '&offset=' +
      offset +
      '&record_count=10';
  } else {
    body =
      'session_id=' +
      session_id +
      '&officer_id=' +
      officer_id +
      '&exclude_unheard=true' +
      '&offset=' +
      offset +
      '&record_count=10';
  }
  const requestOptions = {
    body: body,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/getVoicemails', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}
export function GetVoicemailsWithoutPagination() {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  const requestOptions = {
    body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&exclude_unheard=true',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/getVoicemails', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}
export function UpdateVoicemail(id: any, isheard: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  let voicemailID = id;
  let heard = isheard;

  const requestOptions = {
    body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&voicemailID=' + voicemailID + '&heard=' + heard,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/updateVoicemail', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return 'success';
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function DeleteVoicemail(id: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  let voicemailID = id;
  const requestOptions = {
    body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&voicemailID=' + voicemailID,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/deleteVoicemail', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function PostVoicemail1(Voicemail: any, enrollee_id: any, time: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  const requestOptions = {
    body:
      'session_id=' +
      session_id +
      '&officer_id=' +
      officer_id +
      '&Voicemail=' +
      Voicemail +
      '&enrollee_id=' +
      enrollee_id +
      '&timestamp=' +
      time,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/postVoicemail', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function PostVoicemail(Voicemail: any, enrollee_id: any, time: any) {
  let officer_id: any = localStorage.getItem('officer_id');
  let session_id: any = localStorage.getItem('user');

  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  var urlencoded = new URLSearchParams();
  urlencoded.append('session_id', session_id);
  urlencoded.append('officer_id', officer_id);
  urlencoded.append('enrollee_id', enrollee_id);
  urlencoded.append('Voicemail', Voicemail);
  urlencoded.append('timestamp', time);
  var requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/postVoicemail', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 'Successful') {
            return 'Successful';
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function getUnheardVoicemails(activePage: any, officer_filter = '') {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  let offset = activePage * 10;

  let body = '';
  if (officer_filter === '') {
    body = 'session_id=' + session_id + '&officer_id=' + officer_id + '&offset=' + offset + '&record_count=10';
  } else {
    body =
      'session_id=' +
      session_id +
      '&officer_id=' +
      officer_id +
      '&offset=' +
      offset +
      '&record_count=10' +
      '&officer_filter=' +
      officer_filter;
  }
  const requestOptions = {
    body: body,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/getUnheardVoicemails', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}
