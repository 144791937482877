/**
 * @file   PageLoader.tsx
 * @brief  Page loader component
 * @date   Mar , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import Loader from 'react-loader-spinner';

interface Props {
  show: boolean;
}

export class PageLoader extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="page-loader">
        {/* #7dc242 */}
        <Loader type="Oval" color="#55ff00" height={60} width={60} />
      </div>
    );
  }
}
