/**
 * @file   EnrollEdit.tsxyarn add @mui/material
 * @brief  Enrollee Edit component
 * @date   June , 2021
 * @author ZCO Engineern
 * @copyright (c) 2021, ZCO
 */

import React, { useState, useEffect } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AudioPlayer from 'material-ui-audio-player';
import ReplyIcon from '@material-ui/icons/Reply';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import UnheardIcon from '../css/images/unheard.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TranscriptionIcon from '../css/images/view_record.png';
import AddNote from '../css/images/addNote.png';
import { Box, Grid, Button, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { GetVoicemails, UpdateVoicemail, DeleteVoicemail, getUnheardVoicemails } from './Actions';
import App from '../common/Map';
import { Modal } from 'react-bootstrap';
import { InfoModal } from '../EnrolleeList/InfoModel';
import * as alerts from '../../messages';

import { NoteModel } from './NoteModel';
import { Button as Buttons } from 'react-bootstrap';
import VoiceRecording from './VoiceRecording';
import loadingimg from '../css/images/loading.gif';
import { PageLoader } from '../common/Loader';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { TranscriptionModel } from '../EnrolleeList/TranscriptionModel';
import { ReviewedByModel } from '../EnrolleeList/ReviewedByModel';
import { logout } from '../../functions';
import Pagination from 'react-bootstrap/Pagination';
import PaginationList from './PaginationList';
import 'bootstrap/dist/css/bootstrap.min.css';
interface Props {
  handleBack(type: number): void;
  validateSession(): void;
  fetchVoiceMailAPI(): void;
  VoicemessageData: any;
  enrollee_id?: string;
  VoicemessageNav: string;
  showLoader: boolean;
}

export default function ScheduleUpdate(props: Props) {
  const [voicemails, setVoicemails] = useState<any>([]);
  const [VoicemailsFiltered, setVoicemailsFilter] = useState<any>([]);
  const [voicemailsType, setvoicemailsType] = useState<number>(1);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [showTranscript, setshowTranscript] = useState<boolean>(false);
  const [transcription, setTranscription] = useState<string>('');
  const [showModalInfo, setShowModalInfo] = useState<number>(0);
  const [voicemailId, setVoicemailId] = useState<number>(0);
  const [noteModel, setNoteModel] = useState<boolean>(false);
  const [VoiceReocrdModel, setVoiceReocrdModel] = useState<boolean>(false);
  const [enrolleeId, setEnrolleeId] = useState<string>('');
  const [alertType, setAlertType] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingUnRead, setLoadingUnRead] = useState<boolean>(false);
  const [showLoader, setLoader] = useState<boolean>(false);
  const [voicemaiDate, setVoicemaiDate] = useState<string>('');
  const [unheardSearchText, setUnheardSearchText] = useState<string>('');
  const [searchOfficero, setSearchOfficer] = useState<string>('');
  const [transcriptionModel, setTranscriptionModel] = useState<boolean>(false);
  const [reviewedByModel, setReviewdByModel] = useState<boolean>(false);
  const [reviewdBy, setReviewdBy] = useState<string>('');
  const [sessionout, setSessionOut] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const handPointer = { cursor: 'pointer' };

  useEffect(() => {
    let fetchMyAPI = async () => {
      setLoader(true);
      if (voicemailsType == 3) {
        await fetchUnheardVoicemailAPI(activePage - 1);
      } else await fetchVoiceMailAPI(activePage - 1);
      setLoader(false);
    };
    fetchMyAPI();
  }, [activePage]);

  const fetchVoiceMailAPI = async (activePage: any) => {
    let VoicemessageList: any;
    if (props.VoicemessageNav == 'Main') {
      VoicemessageList = await GetVoicemails(activePage, props.enrollee_id);
    } else {
      VoicemessageList = await GetVoicemails(activePage);
    }
    if (VoicemessageList == 'Session Out') {
      setLoader(false);
      setSessionOut(true);
      return;
    }
    const currentDate = Math.floor(Date.now() / 1000);

    setVoicemails(VoicemessageList);
    let filtered;
    if (voicemailsType == 1) {
      filtered = VoicemessageList.voicemails;
    } else if (voicemailsType == 2) {
      filtered = VoicemessageList.sent_voicemails.filter((a: any) => parseInt(a.dateCreated) <= currentDate);
    } else if (voicemailsType == 4) {
      filtered = VoicemessageList.sent_voicemails.filter((a: any) => parseInt(a.dateCreated) > currentDate);
    } else {
      filtered = VoicemessageList.unheard_voicemails;
    }
    setVoicemailsFilter(filtered ? (filtered.length > 0 ? filtered.sort(voicemailSort) : []) : []);
    setTotalRecords(VoicemessageList.received_total);
  };

  useEffect(() => {
    let fetchMyAPI = async () => {
      if (voicemailsType == 3) {
        setLoader(true);
        await fetchUnheardVoicemailAPI(1);
        setLoader(false);
      }
    };
    fetchMyAPI();
  }, [voicemailsType]);

  const fetchUnheardVoicemailAPI = async (activePage: any, officer_filter = '') => {
    setLoader(true);
    let VoicemessageList = await getUnheardVoicemails(activePage - 1, officer_filter);
    if (VoicemessageList == 'Session Out') {
      setLoader(false);
      setSessionOut(true);
      return;
    }

    let voiceMailsList = voicemails;
    voiceMailsList.unheard_voicemails = VoicemessageList.unheard_voicemails;
    setVoicemails(voiceMailsList);

    let filtered;
    filtered = VoicemessageList.unheard_voicemails;
    setTotalRecords(VoicemessageList.received_total);
    if (props.VoicemessageNav == 'Main') {
      filtered = filtered ? filtered.filter((x: any) => x.enrolleeID == props.enrollee_id) : [];
    }
    setVoicemailsFilter(filtered ? (filtered.length > 0 ? filtered.sort(voicemailSort) : []) : []);
    setLoader(false);
  };

  const voicemailSort = (a: any, b: any) => {
    if (a.readFlag < b.readFlag) {
      return -1;
    }
    if (a.readFlag > b.readFlag) {
      return 1;
    }
    if (a.dateCreated < b.dateCreated) {
      return 1;
    }
    if (a.dateCreated > b.dateCreated) {
      return -1;
    }
    return 0;
  };

  const sentVoicemailSort = (a: any, b: any) => {
    if (a.dateCreated < b.dateCreated) {
      return 1;
    }
    if (a.dateCreated > b.dateCreated) {
      return -1;
    }
    return 0;
  };
  const handlePageChange = (pageNumber: any) => {
    setActivePage(pageNumber);
  };
  const handleChange = async (type: number) => {
    setActivePage(1);
    const currentDate = Math.floor(Date.now() / 1000);
    setvoicemailsType(type);
    let filtered: any = [];
    if (type === 1) {
      setLoader(true);
      await fetchVoiceMailAPI(0);
      setLoader(false);
      filtered =
        props.VoicemessageNav == 'Main'
          ? setVoicemailsFilter(
              voicemails.voicemails
                ? voicemails.voicemails.filter((x: any) => x.enrolleeID == props.enrollee_id).sort(voicemailSort)
                : []
            )
          : setVoicemailsFilter(voicemails.voicemails.sort(voicemailSort));
    } else if (type === 2) {
      filtered =
        props.VoicemessageNav == 'Main'
          ? setVoicemailsFilter(
              voicemails.sent_voicemails
                ? voicemails.sent_voicemails
                    .filter((x: any) => x.enrolleeID == props.enrollee_id && parseInt(x.dateCreated) <= currentDate)
                    .sort(sentVoicemailSort)
                : []
            )
          : setVoicemailsFilter(
              voicemails.sent_voicemails
                .filter((x: any) => parseInt(x.dateCreated) <= currentDate)
                .sort(sentVoicemailSort)
            );
    } else if (type === 4) {
      filtered =
        props.VoicemessageNav == 'Main'
          ? setVoicemailsFilter(
              voicemails.sent_voicemails
                ? voicemails.sent_voicemails
                    .filter((x: any) => x.enrolleeID == props.enrollee_id && parseInt(x.dateCreated) > currentDate)
                    .sort(sentVoicemailSort)
                : []
            )
          : setVoicemailsFilter(
              voicemails.sent_voicemails
                .filter((x: any) => parseInt(x.dateCreated) > currentDate)
                .sort(sentVoicemailSort)
            );
    }
  };
  const handleOfficerChange = (event: any) => {
    setActivePage(1);
    setUnheardSearchText(searchOfficero);
    fetchUnheardVoicemailAPI(1, searchOfficero);
  };
  //Enable searching when taping enter key
  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      setUnheardSearchText(event.target.value);
    }
  };

  const handleClick = (event: any) => {
    setSearchOfficer(event.target.value);
  };

  //Method to show the map
  const showMapButton = (enrollee_id: string, enrollee_location: any) => {
    setLocation(enrollee_location);
    setShowMap(!showMap);
  };

  //Method to show the map
  const showMapButtonProp = () => {
    setShowMap(!showMap);
  };

  const toggleTranscription = (transcription: string) => {
    setTranscription(transcription);
    setshowTranscript(!showTranscript);
  };

  const closeButton = () => {
    return true;
  };

  const onFinished = async (id: number, readFlag: string) => {
    let unheard = true;
    if (voicemailsType == 1 && readFlag == 'false') {
      fetchVoiceMailAPI(activePage - 1);
      props.fetchVoiceMailAPI();
    }
  };

  const onPlay = async (id: number, readFlag: string) => {
    let unheard = true;
    if (voicemailsType == 1 && readFlag == 'false') {
      UpdateVoicemail(id, unheard).then((resposne: any) => {
        if (resposne == 'Session Out') {
          setLoader(false);
          setSessionOut(true);
          return;
        } else if (resposne == 'Error') {
          errorAlert();
          return;
        }
      });
    } else if (voicemailsType == 3 && readFlag == 'false') {
      UpdateVoicemail(id, unheard).then((resposne: any) => {
        if (resposne == 'Session Out') {
          setLoader(false);
          setSessionOut(true);
        } else if (resposne == 'Error') {
          errorAlert();
          return;
        }
      });
    }
  };
  const updateUnheard = async (id: number) => {
    setVoicemailId(id);
    setLoadingUnRead(true);
    UpdateVoicemail(id, false).then((resposne: any) => {
      setVoicemailId(0);
      setLoadingUnRead(false);
      if (resposne == 'Session Out') {
        setLoader(false);
        setSessionOut(true);
      } else if (resposne == 'Error') {
        errorAlert();
        return;
      }
      fetchVoiceMailAPI(activePage - 1);
      props.fetchVoiceMailAPI();
    });
  };

  //Exit chat confirmation
  const showAlert = (id: number) => {
    setAlertType('Delete');
    setShowModalInfo(2);
    setVoicemailId(id);
  };

  const modalClose = () => {
    setVoicemailId(0);
    setShowModalInfo(0);
  };

  const modalConfirm = async () => {
    setLoading(true);

    DeleteVoicemail(voicemailId).then((resposne: any) => {
      if (resposne == 'Session Out') {
        setLoader(false);
        setSessionOut(true);
        return;
      } else if (resposne == 'Error') {
        errorAlert();
        return;
      }
      if (voicemailsType == 3) {
        fetchUnheardVoicemailAPI(activePage);
      } else {
        fetchVoiceMailAPI(activePage - 1);
      }
      setVoicemailId(0);
      setLoading(false);
    });
    setShowModalInfo(0);
  };

  //To open mote modal
  const openNote = (id: number, enrollee_id: string, transcription: string, voicemaiDate: any) => {
    setEnrolleeId(enrollee_id);
    setVoicemailId(id);
    setTranscription(transcription);
    setVoicemaiDate(voicemaiDate);
    setNoteModel(true);
  };

  //To open reviewd modal
  const openReviewdBy = (reviewedBy: string) => {
    setReviewdByModel(true);
    setReviewdBy(reviewedBy);
  };

  //To open mote modal
  const openTranscription = (transcription: string, id: number, readFlag: string) => {
    setTranscription(transcription);
    setTranscriptionModel(true);
    let unheard = true;
    if (voicemailsType == 1 && readFlag == 'false') {
      UpdateVoicemail(id, unheard).then((resposne: any) => {
        if (resposne.errorCode == 5) {
          setLoader(false);
          setSessionOut(true);
        } else if (resposne == 'Error') {
          errorAlert();
          return;
        }
        fetchVoiceMailAPI(activePage - 1);
        props.fetchVoiceMailAPI();
      });
    } else if (voicemailsType == 3 && readFlag == 'false') {
      UpdateVoicemail(id, unheard).then((resposne: any) => {
        if (resposne.errorCode == 5) {
          setSessionOut(true);
        } else if (resposne == 'Error') {
          errorAlert();
          return;
        }
      });
    }
  };

  //Close note modal
  const toggleNote = () => {
    setNoteModel(false);
  };

  //Close Transcription modal
  const toggleTranscriptionModel = () => {
    setTranscriptionModel(false);
  };

  //Close Reviewd By modal
  const toggleReviewedByModel = () => {
    setReviewdByModel(false);
  };

  const openRecording = (id: string) => {
    setVoiceReocrdModel(!VoiceReocrdModel);
    setEnrolleeId(id);
  };

  const toggle = () => {
    setVoiceReocrdModel(!VoiceReocrdModel);
  };

  const errorAlert = () => {
    setAlertType('Error');
    setShowModalInfo(7);
  };

  const successAlert = () => {
    toggle();
    setAlertType('Success');
    setShowModalInfo(7);
  };
  //Handle session out ok
  const handleSessionOut = () => {
    logout();
  };
  function timeConverter(UNIX_timestamp: any) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var timstring = a.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit' });
    var time = month + '/' + date + '/' + year + ' ' + timstring;
    return time;
  }
  const showModal = showModalInfo > 0 ? true : false;
  const msg: any =
    alertType == 'Delete' ? alerts.CONFIRM_DELETE : alertType == 'Success' ? alerts.VOICE_SUCCESS : alerts.ERROR;
  const modal =
    showModalInfo > 0 ? (
      <InfoModal
        enrolleeImg={''}
        show={showModal}
        modelType={showModalInfo}
        modalClose={modalClose}
        modalYes={alertType == 'Delete' ? modalConfirm : modalClose}
        msg={msg}
        notice_msg={''}
      />
    ) : null;

  let serachRecords = false;

  return (
    <div className="enroll-main ml-lg-3 ml-sm-0 mt-4 voicemail-wrap">
      {showLoader ? <PageLoader show={true} /> : null}
      {modal}
      {reviewedByModel && <ReviewedByModel modalClose={toggleReviewedByModel} reviewedBy={reviewdBy} />}
      {noteModel && (
        <NoteModel
          modalClose={toggleNote}
          enrolleeId={enrolleeId}
          transcription={transcription}
          voicemaiDate={voicemaiDate}
        />
      )}
      {transcriptionModel && <TranscriptionModel modalClose={toggleTranscriptionModel} transcription={transcription} />}
      <div className="d-flex justify-content-between button-reponsive">
        <div className="voicemail-heading d-flex">
          {props.VoicemessageNav == 'Main' && (
            <KeyboardBackspaceIcon
              className="mr-4 pointer"
              onClick={() => props.handleBack(props.VoicemessageNav == 'Main' ? 4 : 5)}
            />
          )}
          <h5>Voicemail</h5>
        </div>
        <div className="voicemail-new">
          <FormControl className="voicemail-select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={voicemailsType}
              label="type"
              disableUnderline={true}
              onChange={(event: any) => handleChange(event.target.value)}
            >
              <MenuItem value={1}>Received</MenuItem>
              <MenuItem value={2}>Sent</MenuItem>
              <MenuItem value={4}>Pending</MenuItem>
              {props.VoicemessageNav != 'Main' && <MenuItem value={3}>All Officers Unheard</MenuItem>}
            </Select>
          </FormControl>
          {props.VoicemessageNav == 'Main' && (
            <Buttons
              variant="primary"
              size="sm"
              className="new_voicemail minwidth150"
              disabled={false}
              onClick={() => openRecording(props.enrollee_id ? props.enrollee_id : '')}
            >
              {'NEW VOICEMAIL'}
            </Buttons>
          )}
        </div>
      </div>
      <div>
        {voicemailsType == 3 && (
          <FilledInput
            id="filled-adornment-password"
            type="text"
            onKeyDown={handleEnter}
            className="searchvoice"
            onChange={handleClick}
            placeholder="Search by Officer"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon onClick={handleOfficerChange} style={handPointer} />
              </InputAdornment>
            }
          />
        )}
      </div>
      <div className="voicemail_outer">
        {VoicemailsFiltered.length > 0 ? (
          VoicemailsFiltered.map((voice: any, i: any) => (
            <div key={i}>
              {(voicemailsType == 1 ||
                voicemailsType == 2 ||
                voicemailsType == 3 ||
                (voicemailsType == 4 && unheardSearchText == '') ||
                (voicemailsType == 3 &&
                  voice.assignedOfficer.toLowerCase().includes(unheardSearchText.toLowerCase()) == true)) && (
                <div className="voicemail_main">
                  <div className="col-lg-12 col-md-12">
                    <Box display="flex" alignItems="center" className="voicemsgdetails">
                      <Box
                        className={
                          (voicemailsType == 1 || voicemailsType == 3) && voice.readFlag == 'false'
                            ? 'voice_user red'
                            : 'voice_user'
                        }
                      >
                        {voice.enrolleeName} <span>({voice.enrolleeID})</span>
                      </Box>
                      {voicemailsType == 3 && <Box>Officer's name: {voice.assignedOfficer}</Box>}
                      {voicemailsType == 1 && voice.reviewedBy != '' && (
                        <Box>
                          <Button onClick={() => openReviewdBy(voice.reviewedBy)} style={{ textTransform: 'none' }}>
                            <VisibilityIcon fontSize="small"></VisibilityIcon> &nbsp;Reviewed By
                          </Button>
                        </Box>
                      )}
                      <Box className="voice_date">{timeConverter(voice.dateCreated)}</Box>
                    </Box>

                    <div className="voice_player">
                      <AudioPlayer
                        elevation={0}
                        volume={false}
                        spacing={1}
                        time="double"
                        timePosition="end"
                        src={voice.download_url}
                        onPlayed={() => onPlay(voice.voicemailID, voice.readFlag)}
                        onFinished={() => onFinished(voice.voicemailID, voice.readFlag)}
                      />
                    </div>
                    {voicemailsType == 1 && (
                      <div className="player_options">
                        <Grid container>
                          <Grid item xs>
                            <Button onClick={() => openRecording(voice.enrolleeID)}>
                              <ReplyIcon /> Reply
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button
                              onClick={() =>
                                openNote(
                                  voice.voicemailID,
                                  voice.enrolleeID,
                                  voice.transcription,
                                  timeConverter(voice.dateCreated)
                                )
                              }
                            >
                              <img src={AddNote} /> Add Note
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button onClick={() => showMapButton(voice.enrolleeID, voice.location)}>
                              <LocationOnOutlinedIcon /> Location
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button onClick={() => showAlert(voice.voicemailID)}>
                              <DeleteOutlineOutlinedIcon />{' '}
                              {isLoading && voicemailId == voice.voicemailID ? <img src={loadingimg} /> : 'Delete'}
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button
                              onClick={() => openTranscription(voice.transcription, voice.voicemailID, voice.readFlag)}
                            >
                              <img src={TranscriptionIcon} className="TranscriptionIcon" />
                              Transcribe
                            </Button>
                          </Grid>
                          {voice.readFlag === 'true' && (
                            <Grid item xs>
                              <Button onClick={() => updateUnheard(voice.voicemailID)}>
                                <img src={UnheardIcon} className="TranscriptionIcon" />{' '}
                                {isLoadingUnRead && voicemailId == voice.voicemailID ? (
                                  <img src={loadingimg} />
                                ) : (
                                  'Mark Unheard'
                                )}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    )}

                    {voicemailsType == 2 && (
                      <div className="player_options sent_voicemails">
                        <Grid container>
                          <Grid item xs>
                            <Button onClick={() => showAlert(voice.voicemailID)}>
                              <DeleteOutlineOutlinedIcon />{' '}
                              {isLoading && voicemailId == voice.voicemailID ? <img src={loadingimg} /> : 'Delete'}
                            </Button>
                          </Grid>
                          <Grid item xs>
                            {voice.readFlag == 'true' ? 'Listened' : 'Sent'}
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    {voicemailsType == 4 && (
                      <div className="player_options sent_voicemails">
                        <Grid container>
                          <Grid item xs>
                            <Button onClick={() => showAlert(voice.voicemailID)}>
                              <DeleteOutlineOutlinedIcon />{' '}
                              {isLoading && voicemailId == voice.voicemailID ? <img src={loadingimg} /> : 'Delete'}
                            </Button>
                          </Grid>
                          <Grid item xs>
                            {voice.readFlag == 'true' ? 'Listened' : 'Pending'}
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    {voicemailsType == 3 && (
                      <div className="player_options">
                        <Grid container>
                          <Grid item xs>
                            <Button onClick={() => openRecording(voice.enrolleeID)}>
                              <ReplyIcon /> Reply
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button
                              onClick={() =>
                                openNote(
                                  voice.voicemailID,
                                  voice.enrolleeID,
                                  voice.transcription,
                                  timeConverter(voice.dateCreated)
                                )
                              }
                            >
                              <img src={AddNote} /> Add Note
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button onClick={() => showMapButton(voice.enrolleeID, voice.location)}>
                              <LocationOnOutlinedIcon /> Location
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button onClick={() => showAlert(voice.voicemailID)}>
                              <DeleteOutlineOutlinedIcon />{' '}
                              {isLoading && voicemailId == voice.voicemailID ? <img src={loadingimg} /> : 'Delete'}
                            </Button>
                          </Grid>
                          <Grid item xs>
                            <Button
                              onClick={() => openTranscription(voice.transcription, voice.voicemailID, voice.readFlag)}
                            >
                              <img src={TranscriptionIcon} className="TranscriptionIcon" />
                              Transcribe
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                  {(serachRecords = true)}
                </div>
              )}
            </div>
          ))
        ) : !showLoader ? (
          <div className="voicemail_main" style={{ textAlign: 'center' }}>
            {'No Voicemails to display.'}{' '}
          </div>
        ) : (
          ''
        )}
        {serachRecords == false && VoicemailsFiltered.length > 0 && !showLoader ? (
          <div className="voicemail_main" style={{ textAlign: 'center' }}>
            {'No Voicemails to display.'}{' '}
          </div>
        ) : (
          ''
        )}
      </div>
      {VoicemailsFiltered.length > 0 && (voicemailsType == 1 || voicemailsType == 3) && (
        <div className="d-flex justify-content-center mt-3">
          <PaginationList count={totalRecords} active={activePage} limit={10} setActive={setActivePage} />
        </div>
      )}
      {VoiceReocrdModel && (
        <VoiceRecording
          modalClose={toggle}
          enrolleeId={enrolleeId}
          fetchVoiceMailAPI={fetchVoiceMailAPI}
          fetchUnheardVoicemailAPI={fetchUnheardVoicemailAPI}
          voicemailsType={voicemailsType}
          language={'en-USA'}
          successAlert={successAlert}
          activePage={activePage - 1}
        />
      )}

      {showMap && <App showMapButton={showMapButtonProp} location={location} />}

      {showTranscript && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
          onHide={() => toggleTranscription('')}
        >
          <Modal.Header
            className="record_header"
            closeButton={closeButton()}
            style={{ paddingBottom: '0' }}
          ></Modal.Header>
          <Modal.Body className="record_body" style={{ textAlign: 'center' }}>
            {transcription != '' ? transcription : 'Transcription not available.'}
          </Modal.Body>
        </Modal>
      )}

      {sessionout && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Buttons variant="primary" className="btn btn-primary" onClick={handleSessionOut}>
              OK
            </Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
