import React from 'react';
import { NoteInfoModal } from './NoteInfoModal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useAddNote from '../../../hooks/useAddNote/useAddNote';

import useRoomState from '../../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function AddNoteButton() {
  const roomState = useRoomState();

  const classes = useStyles();

  const { isShowing, toggle } = useAddNote();

  return (
    <div>
      <Tooltip title={'Add Note'} onClick={toggle} placement="top" PopperProps={{ disablePortal: true }}>
        <Fab className={classes.fab}>
          <NoteAddIcon />
        </Fab>
      </Tooltip>

      <NoteInfoModal callstate={roomState} modalClose={toggle} showStatus={isShowing} />

      {/* <CallEndNote callstate={roomState} /> */}
    </div>
  );
}
