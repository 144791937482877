/**
 * @file   ScheduleUpdate.tsx
 * @brief  Schedule update component
 * @date   June , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import loadingimg from '../css/images/loading.gif';
import { Button as Buttons } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { InfoModal } from './InfoModel';
import * as alerts from '../../messages';
import { logout } from '../../functions';

interface Props {
  handleMenu(type: string): void;
  handleBack(type: number): void;
  validateSession(): void;
}

export default function ScheduleUpdate(props: Props) {
  const timeIntervals = 1;
  const [scheduleList, setScheduleList] = useState<any>([]);
  const [intStartDate, setIntStartDate] = useState<Date>(new Date());
  const [curfewStartDate, setCurfewStartDate] = useState<Date>(new Date());
  const [curfewEndDate, setCurfewEndDate] = useState<Date>(new Date());
  const [intTemplate, setintTemplate] = useState<string | null>('');
  const [drugTemplate, setdrugTemplate] = useState<string | null>('');
  const [curfewTemplate, setcurfewTemplate] = useState<string | null>('');
  const [isinterview, setIsinterview] = useState<boolean>(false);
  const [isDrug, setIsDrug] = useState<boolean>(false);
  const [isCurfew, setIsCurfew] = useState<boolean>(false);
  const [successMsg, setsuccessMsg] = useState('');
  const [showModalInfo, setModalInfo] = useState<number>(0);
  const [modelType, setModelType] = useState<string | null>('');
  const [isLoadingInt, setLoadingInt] = useState<boolean>(false);
  const [isLoadingDrug, setLoadingDrug] = useState<boolean>(false);
  const [isLoadingCurfew, setLoadingCurfew] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [sessionout, setSessionOut] = useState<boolean>(false);

  //Form submit handling here
  const formSubmit = (type: string) => {
    if (intStartDate == null || curfewStartDate == null || curfewEndDate == null) {
      setModelType('datevalidationnull');
      setModalInfo(7);
      return;
    }

    let currentTime = new Date().getTime() / 1000;
    let params = '';
    let intStartDateUnix = intStartDate.getTime() / 1000;
    let curfewStartDateUnix = curfewStartDate.getTime() / 1000;
    let curfewEndDateUnix = curfewEndDate.getTime() / 1000;

    let location = scheduleList.locations.length > 0 ? scheduleList.locations[0] : '';

    if (curfewStartDateUnix > curfewEndDateUnix) {
      setModelType('datevalidation');
      setModalInfo(7);
      return;
    }

    //Validatiins to setup the params
    if (type == 'interview') {
      if (intStartDateUnix < currentTime) {
        setModelType('datevalidationcurret');
        setModalInfo(7);
        return;
      }
      setLoadingInt(true);
      setButtonDisable(true);
      params =
        '&start_date_time=' +
        intStartDateUnix +
        '&template_name=' +
        intTemplate +
        '&schedule_type=interview&location=' +
        location;
    }
    if (type == 'drug_test') {
      setLoadingDrug(true);
      setButtonDisable(true);
      params = '&template_name=' + drugTemplate + '&schedule_type=drug_test';
    }
    if (type == 'curfew_outbound') {
      if (curfewStartDateUnix < currentTime || curfewEndDateUnix < currentTime) {
        setModelType('datevalidationcurret');
        setModalInfo(7);
        return;
      }
      setLoadingCurfew(true);
      setButtonDisable(true);
      params =
        '&start_date_time=' +
        curfewStartDateUnix +
        '&end_date_time=' +
        curfewEndDateUnix +
        '&template_name=' +
        curfewTemplate +
        '&schedule_type=curfew_outbound&location=' +
        location;
    }

    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');

    const requestOptions = {
      body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&enrollee_id=' + session_enrollee_id + params,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/add_schedule', requestOptions)
      .then(res =>
        res.json().then(
          data => {
            if (data.response.status == 'Successful') {
              if (type == 'interview') {
                setIsinterview(false);
                setLoadingInt(false);
              }
              if (type == 'drug_test') {
                setIsDrug(false);
                setLoadingDrug(false);
              }
              if (type == 'curfew_outbound') {
                setIsCurfew(false);
                setLoadingCurfew(false);
              }
              setButtonDisable(false);
              setModalInfo(7);
              setModelType('success');
            } else if (data.response.errorCode == 5) {
              setSessionOut(true);
            } else if (data.response.errorCode == 7) {
              setModalInfo(7);
              setModelType('location');
              setButtonDisable(false);
              setLoadingCurfew(false);
              setLoadingDrug(false);
              setLoadingInt(false);
            } else if (data.response.status == 'Failed') {
              setModalInfo(7);
              setModelType('error');
              setButtonDisable(false);
              setLoadingCurfew(false);
              setLoadingDrug(false);
              setLoadingInt(false);
            } else {
              props.validateSession();
            }
          },
          error => {
            setModalInfo(7);
            setModelType('error');
            setButtonDisable(false);
            setLoadingCurfew(false);
            setLoadingDrug(false);
            setLoadingInt(false);
          }
        )
      )
      .catch(error => {
        setModalInfo(7);
        setModelType('error');
        setButtonDisable(false);
        setLoadingCurfew(false);
        setLoadingDrug(false);
        setLoadingInt(false);
      });
  };
  //Method for format time
  const setTime = (type: number) => {
    let time = new Date();
    time.setDate(time.getDate() + 1);
    time.setHours(type);
    time.setMinutes(0);
    time.setMilliseconds(0);
    return time;
  };
  //modal close
  const handleClose = (status: number) => {
    if (modelType === 'success' && !isinterview && !isDrug && !isCurfew) {
      props.handleBack(2);
    }
    setModalInfo(0);
  };
  //modal success
  const modalConfirm = (modalType: number) => {
    if (modelType === 'success' && !isinterview && !isDrug && !isCurfew) {
      props.handleBack(2);
    }
    setModalInfo(0);
  };
  const msg =
    modelType === 'success'
      ? alerts.CONFIRM_SUCCESS
      : modelType === 'datevalidation'
      ? alerts.DATE_VALIDATION
      : modelType === 'datevalidationnull'
      ? alerts.DATE_VALIDATION_NULL
      : modelType === 'datevalidationcurret'
      ? alerts.DATE_VALIDATION_TIME
      : modelType === 'error'
      ? alerts.ERROR
      : modelType === 'location'
      ? alerts.INVALIDLOCATION
      : '';

  const showModal = showModalInfo > 0 ? true : false;
  const modal =
    showModalInfo > 0 ? (
      <InfoModal
        enrolleeImg={''}
        show={showModal}
        modelType={showModalInfo}
        modalClose={handleClose}
        modalYes={modalConfirm}
        msg={msg}
        notice_msg={''}
      />
    ) : null;
  //Handle session out ok
  const handleSessionOut = () => {
    logout();
  };
  useEffect(() => {
    let start = setTime(0);
    let end = setTime(1);
    //setting dafult time
    setIntStartDate(start);
    setCurfewStartDate(start);
    setCurfewEndDate(end);

    let schedule: any = JSON.parse(localStorage.getItem('Schedule') || '[]');
    if (schedule) {
      setScheduleList(schedule);

      //Validatiions to show/hide schedule sections
      if (
        schedule.interview.enabled &&
        !schedule.interview.existingSchedule &&
        schedule.interview.templates.length > 0
      ) {
        setIsinterview(true);
        setintTemplate(schedule.interview.templates[0]);
      }
      if (
        schedule['drug testing'].enabled &&
        !schedule['drug testing'].existingSchedule &&
        schedule['drug testing'].templates.length > 0
      ) {
        setIsDrug(true);
        setdrugTemplate(schedule['drug testing'].templates[0]);
      }
      if (schedule.curfew.enabled && !schedule.curfew.existingSchedule && schedule.curfew.templates.length > 0) {
        setIsCurfew(true);
        setcurfewTemplate(schedule.curfew.templates[0]);
      }
    }
  }, []);

  return (
    <div className="enroll-main ml-lg-3 ml-sm-0 mt-4">
      {' '}
      {modal}
      <div className="d-flex">
        <KeyboardBackspaceIcon className="mr-4 pointer " onClick={() => props.handleBack(2)} />
        <h5>Schedule Update</h5>
      </div>
      {isinterview && (
        <div className="Enroll_edit_main mb-2">
          <div className="col-md-12">
            <h6>INTERVIEW</h6>
            <div className="row mx-15 px-0">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">TEMPLATE</FormLabel>
                <FormControl className="w100">
                  <Select
                    value={intTemplate}
                    onChange={(event: any) => setintTemplate(event.target.value)}
                    displayEmpty
                  >
                    {Object.keys(scheduleList).length > 0 ? (
                      scheduleList.interview ? (
                        scheduleList.interview.templates.map((iTemp: string) => (
                          <MenuItem value={iTemp}>{iTemp}</MenuItem>
                        ))
                      ) : (
                        <MenuItem value={''}>{''}</MenuItem>
                      )
                    ) : (
                      <MenuItem value={''}>{''}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">START DATE</FormLabel>

                <DatePicker
                  className="datetimepicket"
                  selected={intStartDate}
                  onChange={(date: Date) => setIntStartDate(date)}
                  dateFormat="MM-dd-yyyy h:mm aa"
                  timeIntervals={timeIntervals}
                  showTimeInput
                />
              </div>
            </div>
            <Button
              variant="primary"
              size="sm"
              className="minwidth150"
              onClick={() => formSubmit('interview')}
              disabled={buttonDisable}
            >
              {isLoadingInt ? <img src={loadingimg} /> : 'Save'}
            </Button>
          </div>
        </div>
      )}
      {isDrug && (
        <div className="Enroll_edit_main mb-2">
          <div className="col-md-12">
            <h6>DRUG TESTING</h6>
            <div className="row mx-15 px-0">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">TEMPLATE</FormLabel>
                <FormControl className="w100">
                  <Select
                    value={drugTemplate}
                    onChange={(event: any) => setdrugTemplate(event.target.value)}
                    displayEmpty
                  >
                    {Object.keys(scheduleList).length > 0 ? (
                      scheduleList['drug testing'] ? (
                        scheduleList['drug testing'].templates.map((dTemp: any) => (
                          <MenuItem value={dTemp}>{dTemp}</MenuItem>
                        ))
                      ) : (
                        <MenuItem value={''}>{''}</MenuItem>
                      )
                    ) : (
                      <MenuItem value={''}>{''}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <Button
              variant="primary"
              size="sm"
              className="minwidth150"
              onClick={() => formSubmit('drug_test')}
              disabled={buttonDisable}
            >
              {isLoadingDrug ? <img src={loadingimg} /> : 'Save'}
            </Button>
          </div>
        </div>
      )}
      {isCurfew && (
        <div className="Enroll_edit_main">
          <div className="col-md-12">
            <h6>CURFEW</h6>
            <div className="row mx-15 px-0">
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">TEMPLATE</FormLabel>
                <FormControl className="w100">
                  <Select
                    value={curfewTemplate}
                    onChange={(event: any) => setcurfewTemplate(event.target.value)}
                    displayEmpty
                  >
                    {Object.keys(scheduleList).length > 0 ? (
                      scheduleList.curfew ? (
                        scheduleList.curfew.templates.map((cTemp: string) => <MenuItem value={cTemp}>{cTemp}</MenuItem>)
                      ) : (
                        <MenuItem value={''}>{''}</MenuItem>
                      )
                    ) : (
                      <MenuItem value={''}>{''}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">START DATE</FormLabel>
                <DatePicker
                  className="datetimepicket"
                  selected={curfewStartDate}
                  onChange={(date: Date) => setCurfewStartDate(date)}
                  dateFormat="MM-dd-yyyy h:mm aa"
                  timeIntervals={timeIntervals}
                  showTimeInput
                />
              </div>

              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12 fieldmain">
                <FormLabel component="legend">END DATE</FormLabel>
                <DatePicker
                  className="datetimepicket"
                  selected={curfewEndDate}
                  onChange={(date: Date) => setCurfewEndDate(date)}
                  showTimeInput
                  dateFormat="MM-dd-yyyy h:mm aa"
                  timeIntervals={timeIntervals}
                />
              </div>
            </div>
            <Button
              variant="primary"
              size="sm"
              className="minwidth150"
              onClick={() => formSubmit('curfew_outbound')}
              disabled={buttonDisable}
            >
              {isLoadingCurfew ? <img src={loadingimg} /> : 'Save'}
            </Button>
          </div>
        </div>
      )}
      {sessionout && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Buttons variant="primary" className="btn btn-primary" onClick={handleSessionOut}>
              OK
            </Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
