/**
 * @file   Appointments.tsx
 * @brief  Appointment component
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Modal from 'react-bootstrap/Modal';
import * as alerts from '../../messages';
import { logout } from '../../functions';
import AddAppointment from '../css/images/addIcon.png';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentsList from './AppointmentsList';
import AppointmentDetails from './AppointmentDetails';
import AppointmentAddEdit from './AppointmentAddEdit';
import { GetAppointments, DeleteAppointment } from './Actions';

import { PageLoader } from '../common/Loader';
import { Button as Buttons } from 'react-bootstrap';

import moment from 'moment';

interface Props {
  handleMenu(type: string): void;
  handleBack(type: number): void;
  validateSession(): void;
  AppointmentNav: string;
  enrollee_id?: string;
}
interface IAppointment {
  appointmentID: string;
  duration: string;
  DateTime: number;
  Description: string;
}
export default function Appointments(props: Props) {
  const [selectedDate, setSelectDate] = React.useState<Date | null>(null);
  const [selectedDay, setSelectDay] = React.useState<Date | null>(null);
  const [selectedMonthDate, setMonthDateChanged] = React.useState<Date | null>(null);

  const [showDetails, setShowDetails] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showLoader, setLoader] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [sessionout, setSessionOut] = useState<boolean>(false);
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [masterAppointmentsList, setMasterAppointmentsList] = useState([]);
  const [appointment, setAppointment] = useState<IAppointment | null>();
  const [uniqueDates, setUniqueDates] = useState<string[]>([]);
  const [appointmentEdit, setAppointmentForEdit] = useState<IAppointment | null>();
  const [searchKey, setSearchKey] = useState('');
  const [action, setAction] = useState<string>('');
  const handleDetailsClose = () => {
    setShowDetails(false);
    setAppointment(null);
  };

  const handleAddClose = () => {
    setShowAdd(false);
    setAppointmentForEdit(null);
    setIsAdd(false);
    setAction('');
  };
  const handleAddShow = () => {
    setAppointment(null);
    setAppointmentForEdit(null);
    setShowAdd(true);
    setAction('Add');
  };
  useEffect(() => {
    setSelectDate(new Date());
  }, []);
  useEffect(() => {
    if (isAdd) {
      if (selectedDay == null) {
        setSelectDate(new Date());
        fetchAppoiments(new Date(), selectedDay, searchKey);
      } else {
        fetchAppoiments(selectedDay, selectedDay, searchKey);
      }
    }
  }, [isAdd]);
  useEffect(() => {
    if (selectedDate != null) {
      setSelectDay(null);
      fetchAppoiments(selectedDate, null, searchKey);
    }
  }, [selectedDate]);
  useEffect(() => {
    if (selectedMonthDate != null) {
      setSelectDay(null);
      fetchAppoiments(selectedMonthDate, null, searchKey);
    }
  }, [selectedMonthDate]);
  useEffect(() => {
    if (appointment != undefined && appointment != null) {
      setShowDetails(true);
    }
  }, [appointment]);
  const fetchAppoiments = async (date: any, day: any, key: any) => {
    let AppointmentsList: any;
    var y = date.getFullYear(),
      m = date.getMonth();
    var from = new Date(y, m, 1);
    var to = new Date(y, m + 1, 1);
    setLoader(true);
    if (props.AppointmentNav == 'Main') {
      AppointmentsList = await GetAppointments(
        props.enrollee_id,
        new Date(from).getTime() / 1000,
        new Date(to).getTime() / 1000
      );
    } else {
      AppointmentsList = await GetAppointments('', new Date(from).getTime() / 1000, new Date(to).getTime() / 1000);
    }
    setLoader(false);
    if (AppointmentsList == 'Session Out') {
      setLoader(false);
      setSessionOut(true);
      return;
    }
    setAppointmentsList(AppointmentsList.appointments);
    setMasterAppointmentsList(AppointmentsList.appointments);
    let dates: string[] = [];
    AppointmentsList.appointments.forEach((item: any) => {
      var date = moment.unix(item.DateTime).format('MM/DD/YYYY');
      if (!dates.includes(date)) {
        dates.push(date);
      }
    });
    setUniqueDates(dates);
    FilterData(AppointmentsList.appointments, day, key);
  };
  //Handle session out ok
  const handleSessionOut = () => {
    logout();
  };

  const handleDayChanged = (date: any) => {
    if (date !== null) {
      setSelectDay(date);
      var start = moment(date).startOf('day');
      var end = moment(date).endOf('day');
      FilterData(masterAppointmentsList, date, searchKey);
    } else {
      setSelectDay(null);
      fetchAppoiments(new Date(), null, searchKey);
      //FilterData(masterAppointmentsList,null,searchKey)
    }
  };

  const fileterByEntrolle = (searchKey: any) => {
    if (searchKey.trim() !== '') {
      setSearchKey(searchKey);
      FilterData(masterAppointmentsList, selectedDay, searchKey);
    } else {
      setSearchKey('');
      FilterData(masterAppointmentsList, selectedDay, searchKey);
    }
  };
  const FilterData = (appointmentList: any, selectedDay: any, searchKey: any) => {
    var filtereddata = appointmentList;
    if (selectedDay !== null && selectedDay !== '') {
      var start = moment(selectedDay).startOf('day');
      var end = moment(selectedDay).endOf('day');
      filtereddata = filtereddata.filter(
        (item: any) => item.DateTime >= moment(start).unix() && item.DateTime <= moment(end).unix()
      );
    }
    if (searchKey !== null && searchKey !== '') {
      filtereddata = filtereddata.filter(
        (x: any) =>
          x.enrolleeName.toLowerCase().includes(searchKey.toLowerCase()) ||
          x.enrolleeID.toLowerCase().includes(searchKey.toLowerCase())
      );
    }
    setAppointmentsList(filtereddata);
  };
  const deleteAppoinment = async (appointmentID: any) => {
    setLoader(true);
    var result: any = await DeleteAppointment(appointmentID);
    if (result == 'Successful') {
      var masterList = masterAppointmentsList;
      var filtereddata = masterList.filter((x: any) => x.appointmentID === appointmentID);
      let dataToRemove: IAppointment = filtereddata[0];
      let date = dataToRemove?.DateTime;
      masterList.splice(masterList.indexOf(filtereddata[0]), 1);
      setMasterAppointmentsList(masterList);
      filtereddata = appointmentsList.filter((x: any) => x.appointmentID === appointmentID);
      masterList = appointmentsList;
      masterList.splice(masterList.indexOf(filtereddata[0]), 1);

      // Remove calendar marker if the date contains single item
      var formattedDate = moment.unix(date).format('dddd, MMMM Do, YYYY h:mm:ss A');
      var start = moment(moment.unix(date)).startOf('day');
      var end = moment(moment.unix(date)).endOf('day');
      filtereddata = masterAppointmentsList.filter(
        (item: any) => item.DateTime >= moment(start).unix() && item.DateTime <= moment(end).unix()
      );
      if (filtereddata.length === 0) {
        var uniqueDatesCompare = uniqueDates;
        var itemToRemove = uniqueDatesCompare.indexOf(moment.unix(date).format('MM/DD/YYYY'));
        if (itemToRemove > 0) {
          uniqueDatesCompare.splice(itemToRemove, 1);
        }
        setUniqueDates(uniqueDatesCompare);
      }
      setAppointmentsList(masterList);
    } else if (result == 'Session Out') {
      setLoader(false);
      setSessionOut(true);
      return;
    }
    setLoader(false);
  };
  const editAppoinment = (appointmentID: any) => {
    var filtereddata = masterAppointmentsList.filter((x: any) => x.appointmentID === appointmentID);
    setAppointmentForEdit(filtereddata[0]);
    setShowAdd(true);
  };

  return (
    <>
      <div className="enroll-main ml-lg-3 ml-sm-0 mt-4">
        <div className="row">
          <div className="col-xl-5 col-lg-12 col-md-12 ">
            <div className="d-flex justify-content-between pb-5 ">
              <div className="d-flex">
                {props.AppointmentNav == 'Main' && (
                  <KeyboardBackspaceIcon className="mr-4 pointer mt-2" onClick={() => props.handleBack(5)} />
                )}
                <h5>Appointment</h5>
              </div>
              <div className="pointer">
                <img src={AddAppointment} alt="AddAppointment" onClick={handleAddShow} style={{ width: '45px' }} />
              </div>

              <Modal size="lg" className="calenderPopup" show={showDetails} onHide={handleDetailsClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <AppointmentDetails appointment={appointment}></AppointmentDetails>
                </Modal.Body>
              </Modal>
              <AppointmentAddEdit
                setSessionOut={setSessionOut}
                showAdd={showAdd}
                handleAddClose={handleAddClose}
                AppointmentNav={props.AppointmentNav}
                appointment={appointmentEdit}
                setIsAdd={setIsAdd}
                action={action}
                defaultDate={selectedDay}
              ></AppointmentAddEdit>
            </div>

            <AppointmentCalendar
              appointmentsList={appointmentsList}
              uniqueDates={uniqueDates}
              monthChanged={setMonthDateChanged}
              dayChanged={handleDayChanged}
              selectedDate={selectedDate}
            ></AppointmentCalendar>
          </div>

          <AppointmentsList
            appointmentsList={appointmentsList}
            setAppointment={setAppointment}
            fileterByEntrolle={fileterByEntrolle}
            AppointmentNav={props.AppointmentNav}
            deleteAppoinment={deleteAppoinment}
            editAppoinment={editAppoinment}
          ></AppointmentsList>
        </div>
      </div>
      {sessionout && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Buttons variant="primary" className="btn btn-primary" onClick={handleSessionOut}>
              OK
            </Buttons>
          </Modal.Footer>
        </Modal>
      )}
      {showLoader ? <PageLoader show={true} /> : null}
    </>
  );
}
