/**
 * @file   AppointmentCalendar.tsx
 * @brief  Enrollee Edit component
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Badge } from '@material-ui/core';
import moment from 'moment';
const AppointmentCalendar = ({ ...props }) => {
  const [selectedDate, setSelectDate] = React.useState<Date | null>(null);

  // const [initialDate, setInitialDate] = React.useState<Date | null>(null);

  const renderDayInPicker = (date: any, selectedDate: any, dayInCurrentMonth: any, dayComponent: any) => {
    if (props.uniqueDates.length > 0) {
      let isSelected = false;
      checkIfExistAppointment(date) ? (isSelected = true) : (isSelected = false);
      return <Badge badgeContent={isSelected ? '🟢' : undefined}  overlap="rectangular">{dayComponent}</Badge>;
    }
    return dayComponent;
  };

  const checkIfExistAppointment = (currentdate: any) => {
    var datetoCompare: string = moment(currentdate).format('MM/DD/YYYY');
    if (props.uniqueDates.includes(datetoCompare)) return true;
    return false;
  };
  const handleMonthChange = (val: any) => {
    props.monthChanged(val);
  };
  const handleDayChange = (val: any) => {
    if (moment(selectedDate).isSame(moment(val))) {
      // setInitial(moment(val))
      setSelectDate(null);
      props.dayChanged(null);
    } else {
      setSelectDate(val);
      props.dayChanged(val);
    }
  };
  /*const setInitial=(date)=>{
    var check = moment(date, 'YYYY/MM/DD');

      var month = check.format('M');
      var year = check.format('YYYY');
      var startDate = moment([year, parseInt(month) - 1]);
    //  setInitialDate(startDate.toDate());
  }*/
  useEffect(() => {
    if (props.selectedDate !== undefined) {
      setSelectDate(props.selectedDate);
      // setInitial(props.selectedDate);
    }
  }, [props.selectedDate]);
  return (
    <div className="calenderWrap mb-2">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          value={selectedDate}
          onChange={handleDayChange}
          onMonthChange={handleMonthChange}
          renderDay={renderDayInPicker}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default AppointmentCalendar;
