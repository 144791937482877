/**
 * @file   LocationModal.tsx
 * @brief  Location modal componenent
 * @date   June , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */
import * as React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { InfoModal } from './InfoModel';
import * as alerts from '../../messages';
import { Button as Buttons } from 'react-bootstrap';
import { logout } from '../../functions';

type MyProps = { modalClose(status: number): void };
type MyState = {
  showModalInfo: number;
  modelType: string;
  sessionout: boolean;
};

export class LocationModel extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModalInfo: 0,
      modelType: '',
      sessionout: false,
    };
  }
  // Handles modal close
  handleClose = () => {
    this.props.modalClose(1);
  };
  // Handles modal confirm
  modalConfirm = (modalType: number) => {
    this.props.modalClose(1);
  };
  closeButton = () => {
    return true;
  };
  //Handle session out ok
  handleSessionOut = () => {
    logout();
  };
  //Form submit handling here
  onSubmit = (type: string) => {
    let option = type == 'location_facial' ? 'facial' : type == 'location_voice' ? 'voice' : '';
    let mobile_app_token = localStorage.getItem('mobile_app_token');
    let phone_type = localStorage.getItem('phone_type');
    if (mobile_app_token == '' || mobile_app_token == 'undefined') {
      this.setState({ showModalInfo: 7 });
      this.setState({ modelType: 'mobile_app_token' });
      return;
    }
    if (
      (phone_type == 'Land Line' && type == 'location_only') ||
      (phone_type == 'Land Line' && type == 'location_facial')
    ) {
      this.setState({ showModalInfo: 7 });
      this.setState({ modelType: 'landline' });
      return;
    }

    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    const requestOptions = {
      body:
        'session_id=' +
        session_id +
        '&officer_id=' +
        officer_id +
        '&enrollee_id=' +
        session_enrollee_id +
        '&option=' +
        option,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };

    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/location_request', requestOptions)
      .then(res =>
        res.json().then(
          data => {
            if (data.response.status == 'Success') {
              this.setState({ showModalInfo: 7 });
              this.setState({ modelType: 'success' });
            } else if (data.response.errorCode == 5) {
              this.setState({ sessionout: true });
            }
          },
          error => {
            this.setState({ showModalInfo: 7 });
            this.setState({ modelType: 'error' });
          }
        )
      )
      .catch(error => {
        this.setState({ showModalInfo: 7 });
        this.setState({ modelType: 'error' });
      });
  };

  // Modal render function
  render() {
    let enrolleeName = localStorage.getItem('enrolleeName');
    const msg =
      this.state.modelType === 'success'
        ? 'We are attempting to retrieve the location of ' +
          enrolleeName +
          ', this information may not be available immediately. Please login to Shadowtrack to see the location in the Compliance tab.'
        : this.state.modelType === 'mobile_app_token'
        ? alerts.MOBILE_TOKEN_VALIDATION
        : this.state.modelType === 'landline'
        ? alerts.LANDLINE_VALIDATION
        : this.state.modelType === 'error'
        ? alerts.ERROR
        : '';
    const showModal = this.state.showModalInfo > 0 ? true : false;
    const modal =
      this.state.showModalInfo > 0 ? (
        <InfoModal
          enrolleeImg={''}
          show={showModal}
          modelType={this.state.showModalInfo}
          modalClose={this.handleClose}
          modalYes={this.modalConfirm}
          msg={msg}
          notice_msg={''}
        />
      ) : null;
    return (
      <div>
        {' '}
        {modal}
        {this.state.showModalInfo == 0 && (
          <Modal
            show={true}
            animation={false}
            className="enrollee-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            onHide={this.handleClose}
          >
            <Form>
              <Modal.Header closeButton={this.closeButton()} style={{ paddingBottom: '0' }}></Modal.Header>
              <Modal.Body>
                <div className="location_pop">
                  <li onClick={() => this.onSubmit('location_only')}>Location Only</li>

                  <li onClick={() => this.onSubmit('location_voice')}>Location With Voice</li>

                  <li onClick={() => this.onSubmit('location_facial')}>Location With Facial</li>
                </div>
              </Modal.Body>
            </Form>
          </Modal>
        )}
        {this.state.sessionout && (
          <Modal
            show={true}
            animation={false}
            className="enrollee-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
          >
            <Modal.Body>
              {' '}
              <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
            </Modal.Body>
            <Modal.Footer style={{ paddingTop: '0' }}>
              <Buttons variant="primary" className="btn btn-primary" onClick={this.handleSessionOut}>
                OK
              </Buttons>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}
