/**
 * @file   MyMarker.tsx
 * @brief  Map marker component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
const MyMarker = ({ text, tooltip }: any): any => <LocationOnIcon className="locationicon" />;

export default MyMarker;
