import React, { PropsWithChildren, useState } from 'react';
import { Button } from 'react-bootstrap';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import { redirectToList } from '../../functions';
import * as alerts from '../../messages';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import { CallEndNote } from '../Controls/AddNoteButton/CallEndNote';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const roomState = useRoomState();

  const { message, code } = error || {};
  let enhancedMessage = enhanceMessage(message, code) || null;
  enhancedMessage =
    enhancedMessage === '' || enhancedMessage === null
      ? localStorage.getItem('message') != null && localStorage.getItem('message') != ''
        ? localStorage.getItem('message')
        : 'This call will be ended.'
      : enhancedMessage;

  const redirectCheck = () => {
    if (sessionStorage.getItem('note_added') == null) {
      return;
    } else {
      redirectToList();
    }
  };

  const [count, setCount] = useState(0);

  function changecount() {
    dismissError();
    if (sessionStorage.getItem('note_added') == null) {
      setCount(prevCount => prevCount + 1);
    } else {
      redirectCheck();
      dismissError();
    }
  }

  return (
    <div>
      <Dialog
        open={error !== null}
        onClose={() => {
          redirectCheck();
          dismissError();
        }}
        className="error-pop"
        onExit={() => redirectCheck()}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Shadowview</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {enhancedMessage === 'Room completed' ? alerts.CALL_DISCONNECTED : enhancedMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={changecount} className="btn btn-primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {count > 0 ? <CallEndNote callstate={roomState} /> : null}
    </div>
  );
}

export default ErrorDialog;
