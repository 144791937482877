/**
 * @file   VoiceRecording.tsx
 * @brief  Starting component which is the initial of VoiceRecording
 * @date   May , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import * as React from 'react';
import Recorder from './Recorder';
import { Button, Modal, Form } from 'react-bootstrap';
import * as alerts from '../../messages';
import { PostVoicemail } from './Actions';
import DatePicker from 'react-datepicker';
import FormLabel from '@material-ui/core/FormLabel';
import loadingimg from '../css/images/loading.gif';
import * as blobUtil from 'blob-util';

interface AudioDuration {
  h: number | null;
  m: number | null;
  s: number | null;
}

interface AudioObject {
  url: string | null;
  blob: string | null;
  chunks: string | null;
  duration: AudioDuration;
}

interface AppState {
  audioDetails: AudioObject;
  disableUploadBtn: Boolean;
  loader: Boolean;
  errorData: Boolean;
  audioAllowed: Boolean;
  clickUpload: Boolean;
  clickStop: Boolean;
  scheduleTime: any;
  successMsg: boolean;
  error: boolean;
  datetimeValidation: boolean;
  isLoading: boolean;
}

interface AppProps {
  language: string;
  modalClose(): void;
  enrolleeId?: string;
  fetchVoiceMailAPI(activePage: number): void;
  fetchUnheardVoicemailAPI(activePage: number): void;
  voicemailsType: number;
  activePage: number;
  successAlert(): void;
}

class VoiceRecording extends React.Component<AppProps, AppState> {
  private timeIntervals = 1;
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: null,
          m: null,
          s: null,
        },
      },
      disableUploadBtn: true,
      errorData: false,
      audioAllowed: false,
      clickUpload: false,
      clickStop: false,
      scheduleTime: '',
      successMsg: false,
      error: false,
      datetimeValidation: false,
      isLoading: false,
    };
  }

  handleAudioStop = (data: any) => {
    this.setState({ audioDetails: data, disableUploadBtn: false });
  };
  handleClosePopup = () => {
    this.props.modalClose();
  };

  fileToBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      return (reader.onload = () => {
        resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
      });
    });
  };

  async handleAudioUpload(file: any, duration: any) {
    if (file !== null) {
      const newFileName = file;
      var base64data = await blobUtil.blobToBase64String(newFileName);

      let scheduleTimeUnix = this.state.scheduleTime != '' ? this.state.scheduleTime.getTime() / 1000 : '';
      let currentTime = new Date().getTime() / 1000;
      if (scheduleTimeUnix && scheduleTimeUnix < currentTime) {
        this.setState({ datetimeValidation: true });
        return;
      }
      this.setState({ isLoading: true });
      PostVoicemail(base64data, this.props.enrolleeId, scheduleTimeUnix).then((resposne: any) => {
        if (resposne == 'Error') {
          this.setState({ error: true });
          return;
        }
        if (this.props.voicemailsType == 3) {
        } else {
          this.props.fetchVoiceMailAPI(this.props.activePage);
        }
        this.props.successAlert();
      });
    }
    this.handleReset();
  }

  handleReset = async () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      },
    };
    await this.setState({
      audioDetails: reset,
      disableUploadBtn: true,
      successMsg: false,
      error: false,
      datetimeValidation: false,
    });
  };

  clickBack = () => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(JSON.stringify({ clickBack: true }), '*');
  };

  setScheduleTime = (time: any) => {
    this.setState({ scheduleTime: time });
  };

  closeButton = () => {
    return true;
  };

  filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  // Game render function
  render() {
    let errorMSG = this.state.error
      ? alerts.ERROR
      : this.state.errorData
      ? alerts.AN_ERROR_HAS_BEEN_OCCURRED_WHILE_RECORDING
      : this.state.datetimeValidation
      ? alerts.DATE_VALIDATION_TIME
      : '';
    return (
      <div>
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
          onHide={() => this.props.modalClose()}
          backdrop="static"
        >
          <Modal.Header
            className="record_header"
            closeButton={this.closeButton()}
            style={{ paddingBottom: '0' }}
          ></Modal.Header>
          <Modal.Body className="record_body">
            <div className="voice_wrapper">
              <div id="voice-recording-body">
                <div className="heading">
                  <div>{alerts.VOICE_RECORDING}</div>
                </div>

                {errorMSG ? <div className="errorMsg voice-display-flex">{errorMSG}</div> : ''}
                {this.state.successMsg ? (
                  <div className="sucessMsg voice-display-flex">{alerts.VOICE_SUCCESS}</div>
                ) : this.state.isLoading ? (
                  <div className="sucessMsg voice-display-flex">
                    {' '}
                    <img src={loadingimg} />{' '}
                  </div>
                ) : (
                  ''
                )}
                {this.state.loader ? <div className="overlay"></div> : ''}

                <Recorder
                  title={alerts.VOICE_RECORDING}
                  audioURL={this.state.audioDetails.url}
                  hideHeader={true}
                  showUIAudio
                  handleAudioStop={data => this.handleAudioStop(data)}
                  handleAudioUpload={(data, duration) => this.handleAudioUpload(data, duration)}
                  handleReset={() => this.handleReset()}
                  uploadButtonDisabled={this.state.disableUploadBtn}
                  mimeTypeToUseWhenRecording={null}
                  clickUpload={this.state.clickUpload}
                  clickStop={this.state.clickStop}
                  handleClosePopup={() => this.handleClosePopup()}
                  language={'en-USA'}
                />
              </div>
              <div className="voice-display-flex voice-date-picker">
                <div className="Schedule">Schedule</div>
                <div className="datetimepicker">
                  <DatePicker
                    className="datetimepicket"
                    selected={this.state.scheduleTime}
                    onChange={(date: Date) => this.setScheduleTime(date)}
                    showTimeInput
                    dateFormat="MM-dd-yyyy h:mm aa"
                    timeIntervals={this.timeIntervals}
                    minDate={new Date()}
                    filterTime={this.filterPassedTime}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default VoiceRecording;
