import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import enroll from '../css/images/enroll_ico.png';
import voicecall from '../css/images/voice_call.png';
import chat from '../css/images/chat_ico.png';
import videocall from '../css/images/video_call.png';
import location from '../css/images/location_request_ico.png';
import notes from '../css/images/notes_line_ico.png';
import notification from '../css/images/send_notification.png';
import ShadowVmail from '../css/images/ShadowVmail.png';
import Appointment from '../css/images/appointment-black.png';

interface Props {
  handleMenu(type: string): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);
export class Menu extends React.Component<Props> {
  render() {
    return (
      <div className="new_list">
        <div className="row px-0">
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Edit')}>
              <span>
                <img src={enroll} alt="Enroll or Update" />
              </span>
              Enroll or Update
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-block voicecall">
            <li onClick={() => alert('Coming Soon')}>
              <span>
                <img src={voicecall} alt="Voice Call" />
              </span>
              Voice Call
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Chat')}>
              <span>
                <img src={chat} alt="Chat" />
              </span>
              Chat
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Video')}>
              <span>
                <img src={videocall} alt="Video Call" />
              </span>
              Video Call
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Location')}>
              <span>
                <img src={location} alt="Location Request" />
              </span>
              Location Request
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Note')}>
              <span>
                <img src={notes} alt="Notes" />
              </span>
              Notes
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('SMS')}>
              <span>
                <img src={notification} alt="Notes" />
              </span>
              Send SMS Notification
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Voice')}>
              <span>
                <img src={ShadowVmail} alt="Voice message" />
              </span>
              Voicemail
            </li>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-6 cursor-pointer">
            <li onClick={() => this.props.handleMenu('Appointments')}>
              <span>
                <img src={Appointment} alt="Appointment" />
              </span>
              Appointment
            </li>
          </div>
        </div>
      </div>
    );
  }
}
