/**
 * @file   AppointmentDetails.tsx
 * @brief  Appointment details component
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from 'react';
import CalenderYear from '../css/images/calenderMonthYear.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
interface IAppointment {
  duration: number;
  DateTime: any;
  Description: string;
}
const AppointmentDetails = ({ ...props }) => {
  const [appointment, setAppointment] = useState<IAppointment>();
  const [duration, setDuration] = useState<string>('');
  useEffect(() => {
    if (props.appointment !== null && props.appointment !== undefined) {
      setAppointment(props.appointment);
      if (props.appointment.duration) {
        var hours = props.appointment.duration / 60;
        var rhours: any = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes: any = Math.round(minutes);
        if (rhours.toString().length == 1) {
          rhours = '0' + rhours.toString();
        }
        if (rminutes.toString().length == 1) {
          rminutes = '0' + rminutes.toString();
        }
        setDuration(rhours + ':' + rminutes);
      }
    }
  }, [props.appointment]);
  return (
    <>
      <div className="bdrBtm pb-3 mb-4 dateTimeWrap">
        <div className=" d-flex">
          <div className="mr-5">
            <img src={CalenderYear} style={{ width: '20px' }} className="mr-2 mb-1 ml-2" />{' '}
            {moment.unix(appointment?.DateTime).format('MMMM-DD-YYYY')}{' '}
          </div>
          <div className="mr-5">
            <AccessTimeIcon className="mb-1" /> {moment.unix(appointment?.DateTime).format('h:mm A')}{' '}
          </div>
          <div className="mr-5">Duration (HH:MM):{duration}</div>
        </div>
      </div>
      <p>{appointment?.Description}</p>
    </>
  );
};
export default AppointmentDetails;
