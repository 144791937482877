/**
 * @file   map.tsx
 * @brief  Map main component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../css/map.css';
import MapRender from '../common/MapRender';
import Box from '@material-ui/core/Box';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import loadingimg from '../css/images/loading.gif';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import * as alerts from '../../messages';
import { logout } from '../../functions';

interface Props {
  showMapButton(): void;
  location?: any;
}

export default function App(props: Props) {
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [isLocation, setIsLocation] = useState<boolean>(false);
  const [noLocation, setNoLocation] = useState<boolean>(false);
  const [sessionout, setSessionOut] = useState<boolean>(false);

  useEffect((): any => {
    getLocation();
  }, []);
  //Handle session out ok
  const handleSessionOut = () => {
    logout();
  };
  //Method to get entrolee's current location
  const getLocation = async () => {
    if (props.location) {
      var locationdata = props.location.split(',');
      if (locationdata[0] && locationdata[0] != 0) {
        setLatitude(locationdata[0]);
        setLongitude(locationdata[1]);
        setIsLocation(true);
      } else {
        setNoLocation(true);
      }
      return;
    }

    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    let room_name = sessionStorage.getItem('room_name');

    const requestOptions = {
      body:
        'session_id=' +
        session_id +
        '&officer_id=' +
        officer_id +
        '&enrollee_id=' +
        session_enrollee_id +
        '&room_id=' +
        room_name,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };

    return await fetch(
      process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/get_location',
      requestOptions
    ).then(res =>
      res.json().then(
        async data => {
          if (data.response.status == 'Successful') {
            setLatitude(data.response.latitude);
            setLongitude(data.response.longitude);
            if (
              data.response.latitude != '' &&
              data.response.latitude != 0 &&
              data.response.longitude != '' &&
              data.response.longitude != 0
            ) {
              setIsLocation(true);
            } else {
              setNoLocation(true);
            }
          } else if (data.response.errorCode == 5) {
            setSessionOut(true);
          }
        },
        error => 'error'
      )
    );
  };
  return (
    <div className="App">
      <span className="closebtn_outer">
        <CancelRoundedIcon
          className="closebtn"
          onClick={() => {
            props.showMapButton();
          }}
        />
      </span>
      {isLocation && <MapRender latitude={latitude} longitude={longitude} />}
      {!isLocation && (
        <Box>
          {noLocation && <LocationOffIcon className="nolocationicon" />}
          {noLocation ? 'Unable to fetch location, please try again later.' : ''}
          {!noLocation && <img src={loadingimg} />}
        </Box>
      )}
      {sessionout && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Button variant="primary" className="btn btn-primary" onClick={handleSessionOut}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
