/**
 * @file   MapRender.tsx
 * @brief  Map render component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */
import React, { useState, useEffect } from 'react';
import '../css/map.css';
import GoogleMapReact from 'google-map-react';
import MyMarker from '../common/MyMarker';


interface Props {
    latitude: number;
    longitude: number; 
  }
export default function MapRender(props: Props) {    

  const distanceToMouse = (pt: any, mp: any): any => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
    }
  };
  const points = [{ id: 1, title: '', lat: Number(props.latitude), lng: Number(props.longitude)}];
  const defaultCenter = { lat: Number(props.latitude), lng: Number(props.longitude) };
  return (     
      <GoogleMapReact
        bootstrapURLKeys={{         
          key: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : '',
          language: 'en',
          region: 'US',
        }}
        defaultCenter={{ lat: Number(props.latitude), lng: Number(props.longitude)}}
        defaultZoom={18}
        distanceToMouse={distanceToMouse}
      >
        {points.map(({ lat, lng, id, title }) => {
          return <MyMarker key={id} lat={lat} lng={lng} text={id} tooltip={title} />;
        })}
      </GoogleMapReact> 
  );
}
