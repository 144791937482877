/**
 * @file   Actions.tsx
 * @brief  Appointments Actions
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

export function GetAppointments(entrolleid = '', from: any, to: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');

  let body;
  if (entrolleid)
    body =
      'session_id=' +
      session_id +
      '&officer_id=' +
      officer_id +
      '&enrollee_id=' +
      entrolleid +
      '&from=' +
      from +
      '&to=' +
      to;
  else body = 'session_id=' + session_id + '&officer_id=' + officer_id + '&from=' + from + '&to=' + to;
  const requestOptions = {
    body: body,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/getAppointments', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function PostAppointment(appointment: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');

  let body;

  if (appointment.id)
    body =
      'session_id=' +
      session_id +
      '&enrollee_id=' +
      appointment.entrolleid +
      '&officer_id=' +
      officer_id +
      '&timestamp=' +
      appointment.timestamp +
      '&description=' +
      appointment.description +
      '&duration=' +
      appointment.duration +
      '&duration_type=' +
      appointment.duration_type +
      '&appointmentID=' +
      appointment.id;
  else
    body =
      'session_id=' +
      session_id +
      '&enrollee_id=' +
      appointment.entrolleid +
      '&officer_id=' +
      officer_id +
      '&timestamp=' +
      appointment.timestamp +
      '&description=' +
      appointment.description +
      '&duration=' +
      appointment.duration +
      '&duration_type=' +
      appointment.duration_type;

  const requestOptions = {
    body: body,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/postAppointment', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 'Successful') {
            return 'Successful';
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}

export function DeleteAppointment(id: any) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');
  let voicemailID = id;
  const requestOptions = {
    body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&appointmentID=' + id,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/cancelAppointment', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 'Successful') {
            return 'Successful';
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            //this.props.validateSession();
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}
export function SearchEnrollee(searchKey: string) {
  let officer_id = localStorage.getItem('officer_id');
  let session_id = localStorage.getItem('user');

  let body;
  body = 'session_id=' + session_id + '&filter=' + searchKey + '&officer_id=' + officer_id + '&version=v2';

  const requestOptions = {
    body: body,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  };
  return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/info', requestOptions)
    .then(res =>
      res.json().then(
        data => {
          if (data.response.status == 0) {
            return data.response;
          } else if (data.response.errorCode == 5) {
            return 'Session Out';
          } else {
            return 'Error';
          }
        },
        error => {
          return 'Error';
        }
      )
    )
    .catch(error => {
      return 'Error';
    });
}
