/**
 * @file   Login.tsx
 * @brief  Login component
 * @date   Apr , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import { isUndefined } from 'util';
import bklogo from '../css/images/bk-logo.png';
import iconpwd from '../css/images/icn-paswd.png';
import iconuser from '../css/images/icn-usename.png';
import '../css/style.css';
import { PageLoader } from '../common/Loader';
import { checkSession } from '../common/SessionCheck';

interface AppState {
  username: string;
  password: string;
  showLoader: boolean;
  submitted: boolean;
  loading: boolean;
  error: string;
}

class Login extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    checkSession();
    this.state = {
      error: '',
      loading: false,
      password: '',
      showLoader: false,
      submitted: false,
      username: '',
    };
  }

  // Reset game board
  reset = () => {
    const state = {
      error: '',
      loading: false,
      password: '',
      showLoader: false,
      submitted: false,
      username: '',
    };
    if (isUndefined(this.state)) {
      this.setState(state);
    } else {
      this.state = state;
    }
  };

  handleChange = (event: any) => {
    if (event.target.name === 'username') {
      this.setState({ username: event.target.value });
    }
    if (event.target.name === 'password') {
      this.setState({ password: event.target.value });
    }
  };

  handleSubmit = (e: any) => {
    e.preventDefault();

    this.setState({ submitted: true, showLoader: true });
    if (this.state === null) {
      this.setState({ showLoader: false });
      return;
    }
    const { username, password } = this.state;

    // stop here if form is invalid
    if (!(username && password)) {
      this.setState({ showLoader: false });
      return;
    }
    let body = 'Username=' + username + '&Password=' + password;
    if (localStorage.getItem('push_token')) body += '&DeviceToken=' + localStorage.getItem('push_token');
    const requestOptions = {
      body: body,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/login', requestOptions)
      .then(res =>
        res
          .json()
          .then(
            data => {
              const result = JSON.parse(JSON.stringify(data));
              
              if (result.response.errorCode !== 0) {
                let errorMessage = result.response.errorMessage;
                //errorMessage = errorMessage.split(':')[1];
                errorMessage = "Username or password is incorrect."
                this.setState({ error: errorMessage, loading: false, showLoader: false });
              } else {
                const name =
                  typeof result.response.name == 'undefined' ||
                  (typeof result.response.name != 'undefined' && result.response.name.trim().length == 0)
                    ? username
                    : result.response.name;
                localStorage.setItem('user', result.response.SessionToken);
                localStorage.setItem('username', name);
                localStorage.setItem('officer_id', result.response.officer_id);
                localStorage.setItem('ContactTypes', JSON.stringify(result.response.ContactTypes));
                window.location.href = '/EnrolleeList';
                localStorage.setItem('storeData', ' ');
              }
            },
            error => this.setState({ error, loading: false })
          )
          .catch(error => {
            alert('Unable to connect to the server, please try agin later.');
          })
      )
      .catch(error => {
        alert('Unable to connect to the server, please try agin later.');
      });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state && this.state.showLoader ? <PageLoader show={true} /> : null}
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-xl-8 col-lg-7 col-md-12 bg-image"></div>
          <div className="col-md-12 col-lg-5 col-xl-4">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-10 mx-auto">
                    <h3 className="login-heading mb-4">
                      <img src={bklogo} alt="logo" />
                    </h3>
                    <form onSubmit={this.handleSubmit}>
                      {this.state && this.state.error && <div className={'alert alert-danger'}>{this.state.error}</div>}
                      <div className="form-group form-log">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state ? this.state.username : ''}
                          onChange={this.handleChange}
                          name="username"
                          aria-describedby="emailHelp"
                          placeholder="USERNAME"
                        />
                        <img src={iconuser} alt="user" />
                        {this.state && this.state.submitted && !this.state.username && (
                          <small className="text-danger d-block pl-3 pt-1">Username is required</small>
                        )}
                      </div>

                      <div className="form-group form-log">
                        <input
                          type="password"
                          name="password"
                          value={this.state ? this.state.password : ''}
                          onChange={this.handleChange}
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="PASSWORD"
                        />
                        <img src={iconpwd} alt="pwd" />
                        {this.state && this.state.submitted && !this.state.password && (
                          <small className="text-danger d-block pl-3 pt-1">Password is required</small>
                        )}
                      </div>
                      <button
                        type="submit"
                        disabled={this.state && this.state.loading}
                        className="btn btn-primary btn-sign"
                      >
                        LOGIN
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
