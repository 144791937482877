/**
 * @file   messages.js
 * @brief  javascript file to config messages used
 * @date   May , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */

export const CONFIRM_START_CHAT = 'Do you want to start a video conference with ';
export const CONFIRM_LOGOUT = 'Are you sure you want to logout?';
export const CONFIRM_EXITROOM = 'The enrollee has disconnected.  Do you want to exit from the room?';
export const CALL_DISCONNECTED = 'The enrollee has disconnected.  Do you want to exit this video?';
//export const NO_PARTICIPANT = 'The call has been disconnected.';
// export const NO_PARTICIPANT = 'This call will be ended as no participant has joined.';
export const NO_PARTICIPANT = 'This person is unavailable.';
export const NO_CAMERA_PERMISSION =
  'Enable your Camera and Microphone permissions from the browser settings to continue.';
export const NOTICE =
  'NOTICE! You must set "Allow" for camera and microphone permissions from your browser settings for video conferencing.';
export const NOTICE_VIDEO = 'NOTICE! If you experience any problems with the video, please mute/unmute the video.';
export const CONFIRM_SCHEDULE = 'Enrollment successfully updated. Press Continue to assign a schedule or Exit.';
export const CONFIRM_NOSCHEDULE =
  'Enrollment successfully updated. The Offender currently has schedule(s). If you would like to make any changes please visit the Shadowtrack Application.';
export const CONFIRM_NOSCHEDULE_TEMPLATE =
  'There is no schedules to create. If you would like to make any changes please visit the Shadowtrack Application';
export const CONFIRM_SUCCESS = 'Schedule successfully created.';
export const DATE_VALIDATION = 'Start Date should be earlier than the End Date.';
export const DATE_VALIDATION_NULL = 'Enter Date and Time.';
export const DATE_VALIDATION_TIME = 'Date and Time should not be earlier than the current time.';
export const MOBILE_TOKEN_VALIDATION =
  'This option is not available until the enrollee has logged into the mobile app.';
export const LANDLINE_VALIDATION = 'This option is not available for Landline enrollees.';
export const ERROR = 'Unexpected error has occurred. please try again later.';
export const CHAT_CONFIRMATION = 'Do you want to start a chat with the enrollee ';
export const END_CHAT = 'Do you want to end this chat?';
export const MEMBER_LEFT = 'The enrollee has disconnected. Will exit from chat now.';
export const IDLE_CHAT = 'Do you want to continue chat?';
export const IDLE_CHAT_LONGTIME = 'Chat is idle for long time. Will exit from chat now.';
export const ERROR_CONNECTING = 'Network not reachable.';
export const ERROR_CONNECTED = 'Network reachable.';
export const MOBILE_TOKEN_VALIDATION_CAHT =
  'You cannot do a chat/call with this enrollee as this enrollee is not logged into the app.';
export const CONFIRM_DELETE = 'Are you sure you want to delete?';
export const PRESS_MICROPHONE_TO_RECORD = 'Tap on the Voice Record button below to record your voice message.';
export const INFO_MAX_RECORD_TIME_REACHED = 'The maximum audio recording allowed is 1 minute.';
export const ERROR_MAX_RECORD_TIME_REACHED = 'Audio length should not exceed 1 minute.';
export const VOICE_RECORDING = '';
export const AN_ERROR_HAS_BEEN_OCCURRED_WHILE_RECORDING = 'An error has occured while recording.';
export const VOICE_SUCCESS = 'Your Voicemail has been sent.';
export const SESSION_OUT =
  'You may be logged into more than one device or your session has expired.  You are now being logged out.';
export const CHANNEL_EXCEPTION = "I'm sorry, we were unable to start the chat.  Please try again later.";
export const INVALIDLOCATION = 'You cannot create a schedule since location not available.';
export const ENABLE_MIC =
  'NOTICE! You must set "Allow" for microphone permissions from your browser settings and also make sure the microphone is connected to your machine for voice recording.';
export const CONFIRM_DELETE_APPOINTMENT = 'Are you sure you want to delete this appointment?';
export const CONFIRM_MOBILE_WITHOUT_APP =
  'You are limiting the functionalities for both the officer and the enrollee. Are you sure you want to continue?';
