/**
 * @file   ChatScreen.tsx
 * @brief  Chat main component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import * as alerts from '../../messages';
import ChatItem from './ChatItem';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';
import { PageLoader } from '../common/Loader';
import { NoteModel } from '../EnrolleeList/NoteModel';
import App from '../common/Map';
import { InfoModal } from '../EnrolleeList/InfoModel';
import { Button as Buttons } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { logout } from '../../functions';

const { Client } = require('@twilio/conversations');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

interface State {
  text: string;
  messages: Array<any>;
  loading: boolean;
  channel: any;
  email: string | null;
  room: string | null;
  showLoader: boolean;
  officerAccessCode: string | null;
  EnrolleeIdentity: string;
  CallID: string | null;
  ChannelID: string | null;
  NoteModel: boolean;
  showMap: boolean;
  showModalInfo: number;
  lastMsgTime: Date;
  lastLongMsgTime: Date;
  alertType: string;
  networkAlertType: string;
  isTyping: boolean;
  sessionout: boolean;
  exception: boolean;
}

interface Props {
  handleBack(type: number): void;
  validateSession(): void;
}

class ChatScreen extends React.Component<Props, State> {
  private interval: any;
  private intervalParticioantJoin: any;
  private scrollDiv: any;
  private intervalDuration = 1000; //milliseconds
  private intervalTime1 = 180; //seconds
  private intervalTime2 = 300; //seconds

  constructor(props: Props) {
    super(props);

    this.state = {
      text: '',
      messages: [],
      loading: false,
      channel: null,
      email: null,
      room: '',
      showLoader: false,
      officerAccessCode: '',
      EnrolleeIdentity: '',
      CallID: '',
      ChannelID: '',
      NoteModel: false,
      showMap: false,
      showModalInfo: 0,
      lastMsgTime: new Date(),
      lastLongMsgTime: new Date(),
      alertType: '',
      networkAlertType: '',
      isTyping: false,
      sessionout: false,
      exception: false,
    };

    this.scrollDiv = React.createRef();
  }
  //Method to join a channel
  joinChannel = async (channel: any) => {
    if (channel.channelState === undefined || channel.channelState.status !== 'joined') {
      try {
        this.setState({ showLoader: true });
        await channel.add(this.state.EnrolleeIdentity);
        // if (this.intervalParticioantJoin)
        // clearInterval(this.intervalParticioantJoin);
        this.setState({ showLoader: false });

        this.setState({
          channel: channel,
          loading: false,
        });
        channel.on('messageAdded', this.handleMessageAdded);

        //set up the listener for the typing started Conversation event
        channel.on('typingStarted', this.typingStart);

        //set  the listener for the typing ended Conversation event
        channel.on('typingEnded', this.typingEnd);
      } catch {
        console.log('Connecting');
        setTimeout(async () => {
          await this.joinChannel(this.state.ChannelID);
        }, 8000);
      }
    }
  };
  typingStart = () => {
    this.setState({ isTyping: true });
    this.setState({ lastMsgTime: new Date(), lastLongMsgTime: new Date() });
  };
  typingEnd = () => {
    this.setState({ isTyping: false });
  };

  handleExceptionOK = () => {
    this.setState({ exception: false });
  };
  //Method to handle send/receive msessages
  handleMessageAdded = (message: any) => {
    this.setState({ lastMsgTime: new Date(), lastLongMsgTime: new Date() });
    const { messages } = this.state;
    this.setState(
      {
        messages: [...messages, message],
      },
      this.scrollToBottom
    );
  };
  //Method to show latest message at the bottom of the  list.
  scrollToBottom = () => {
    if (this.scrollDiv.current !== null) {
      const scrollHeight = this.scrollDiv.current.scrollHeight;
      const height = this.scrollDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  //Method to listen member activity
  listenMember = (channel: any) => {
    if (channel !== undefined && channel !== null) {
      channel.on('participantLeft', (member: any) => {
        this.setState({ showModalInfo: 7, alertType: 'participantLeft' });
      });
      channel.on('participantJoined', (member: any) => {});
    }
  };
  //Method for get time difference in seconds
  diff_minutes = (dt2: Date, dt1: Date) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  };
  //Method to check the ideal time
  checkInterval = () => {
    let diff: number = 0;
    let diffLong: number = 0;
    diffLong = this.diff_minutes(new Date(), this.state.lastLongMsgTime);
    diff = this.diff_minutes(new Date(), this.state.lastMsgTime);
    if (diffLong > this.intervalTime2) {
      this.setState({ showModalInfo: 7, alertType: 'idlelong' });
      return;
    }
    if (diff > this.intervalTime1) {
      this.setState({ showModalInfo: 9, alertType: 'idle' });
      return;
    }
  };
  //To open mote modal
  openNote = () => {
    this.setState({ NoteModel: true });
  };
  //Close note modal
  toggleNote = () => {
    this.setState({ NoteModel: false });
    this.setState({ showModalInfo: 0 });
    this.props.handleBack(3);
  };
  //Method for sending message when taping enter key
  handleEnter = (event: any) => {
    this.setState({ lastMsgTime: new Date(), lastLongMsgTime: new Date() });
    if (event.key === 'Enter') {
      this.sendMessage();
    } else {
      // else send the Typing Indicator signal
      const { channel } = this.state;
      channel.typing();
    }
  };
  //Method to show the map
  showMapButton = () => {
    this.setState({ showMap: !this.state.showMap });
  };
  //Exit chat confirmation
  showAlert = () => {
    this.setState({ showModalInfo: 2, alertType: 'exit' });
  };
  //Method for close alert msg
  modalClose = () => {
    this.setState({ showModalInfo: 0, alertType: '' });
    if (this.state.alertType == 'error') {
      this.props.handleBack(3);
      return;
    }
    if (this.state.alertType == 'idle') {
      this.setState({ lastMsgTime: new Date() });
      this.state.channel.delete().then(function(channel: any) {});
      this.openNote();
      return;
    }
  };
  //Method to handle alert confirmations
  modalConfirm = () => {
    if (this.state.alertType == 'error') {
      this.props.handleBack(3);
      return;
    }

    if (this.state.alertType == 'idle') {
      this.setState({ lastMsgTime: new Date() });
      this.setState({ showModalInfo: 0 });
      return;
    }
    this.setState({ lastMsgTime: new Date() });
    if (this.state.channel?._participants !== undefined) {
      const iterator = this.state.channel?._participants.keys();
      for (var i = 0; i < this.state.channel?._participants.size; i++) {
        const participant = iterator.next().value;
        this.state.channel?.removeParticipant(participant);
      }
    }
    this.openNote();
  };
  //Handle session out ok
  handleSessionOut = () => {
    logout();
  };
  componentDidMount = async () => {
    this.setState({ showLoader: true });
    let token: string = '';
    let officer_id = await localStorage.getItem('officer_id');
    let email = officer_id;
    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');

    var min = 1;
    var max = 100;
    var rand = min + Math.random() * (max - min);
    let room = officer_id + '_' + session_enrollee_id + rand;
    this.setState({ loading: true, email: email, room: room });
    try {
      token = await this.getToken();
    } catch {
      throw new Error('Unable to get token, please reload this page');
    }

    if (token) {
      //  const client = await Chat.Client.create(token);
      const client = new Client(token);

      await client.on('connectionStateChanged', async (message: any) => {
        if (message == 'connecting') {
          this.setState({ showLoader: true });
        }
        return;
      });
      if (this.state.alertType !== 'connecting') {
        client.on('tokenAboutToExpire', async () => {
          const token = await this.getToken();
          client.updateToken(token);
        });

        await client.getSubscribedConversations().then(async (paginator: any, i: any) => {
          for (i = 0; i < paginator.items.length; i++) {
            const channel = paginator.items[i];

            if (channel.participants !== undefined) {
              const iterator = channel.participants.keys();
              for (var j = 0; j < channel.participants.size; j++) {
                const participant = iterator.next().value;
                await channel.removeParticipant(participant);
              }
            }
          }
        });

        client.on('tokenAboutToExpire', async () => {
          const token = await this.getToken();
          client.updateToken(token);
        });

        client.on('tokenExpired', async () => {
          const token = await this.getToken();
          client.updateToken(token);
        });
        try {
          this.setState({ showModalInfo: 0, alertType: 'exception' });

          const channel = await client.createConversation({
            uniqueName: room,
            friendlyName: room,
          });

          this.setState({ ChannelID: channel });
          sessionStorage.removeItem('room_name');
          sessionStorage.setItem('room_name', channel.sid);
          await this.updateChat(channel.sid);
          await channel.join();
          await this.joinChannel(channel);
          this.listenMember(channel);
        } catch (err) {
          this.reportError(sessionStorage.getItem('room_name'));
          this.setState({ exception: true });
          this.setState({ showLoader: false });
          console.log('Error', err);
          throw new Error('Error');
        }

        client.on('channelJoined', async (channel: any) => {
          const messages = await channel.getMessages();
          this.setState({ messages: messages.items || [] });
        });
        this.setState({ lastMsgTime: new Date(), lastLongMsgTime: new Date() });
        this.interval = setInterval(() => this.checkInterval(), this.intervalDuration);
        this.scrollToBottom();
        //  this.setState({ showLoader: false });
      }
    }
  };

  static getDerivedStateFromError(error: any) {
    window.location.href = '/EnrolleeList';
  }
  componentDidCatch(error: any, errorInfo: any) {
    window.location.href = '/EnrolleeList';
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  //Method to Get the twillio token from the server
  getToken = async () => {
    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    const requestOptions = {
      body:
        'session_id=' +
        session_id +
        '&officer_id=' +
        officer_id +
        '&enrollee_id=' +
        session_enrollee_id +
        '&version=v2',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/start_chat', requestOptions)
      .then(res =>
        res.json().then(
          data => {
            if (data.response.status == 'Success') {
              this.setState({
                officerAccessCode: data.response.officerAccessCode,
                EnrolleeIdentity: data.response.EnrolleeIdentity,
                CallID: data.response.CallID,
              });
              return data.response.officerAccessCode;
            } else if (data.response.errorCode == 5) {
              this.setState({ sessionout: true });
            } else {
              this.props.validateSession();
            }
          },
          error => {
            this.setState({ showModalInfo: 7, alertType: 'error' });
          }
        )
      )
      .catch(error => {
        this.setState({ showModalInfo: 7, alertType: 'error' });
      });
  };
  //Method to send the channel details to the server
  updateChat = async (ChannelID: any) => {
    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    let CallID = this.state.CallID;
    const requestOptions = {
      body: 'session_id=' + session_id + '&ChannelID=' + ChannelID + '&CallID=' + CallID,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/update_chat', requestOptions)
      .then(res =>
        res.json().then(
          data => {},
          error => {
            this.setState({ showModalInfo: 7, alertType: 'error' });
          }
        )
      )
      .catch(error => {
        this.setState({ showModalInfo: 7, alertType: 'error' });
      });
  };

  reportError = async (ChannelID: any) => {
    let session_enrollee_id = sessionStorage.getItem('session_enrollee_id');
    let officer_id = localStorage.getItem('officer_id');
    let session_id = localStorage.getItem('user');
    let CallID = this.state.CallID;
    let chatParms = '';
    if (CallID) {
      chatParms = '&CallID=' + CallID + '&room_id=' + ChannelID;
    }
    let types = JSON.parse(localStorage.getItem('ContactTypes') || '[]');

    const requestOptions = {
      body:
        'session_id=' +
        session_id +
        '&officer_id=' +
        officer_id +
        '&enrollee_id=' +
        session_enrollee_id +
        '&note=' +
        'Chat initiation failed' +
        '&contact_type=' +
        types +
        chatParms,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/add_note', requestOptions)
      .then(res =>
        res.json().then(
          data => {},
          error => {}
        )
      )
      .catch(error => {});
  };
  //Twillio method to send messages.
  sendMessage = () => {
    const { text, channel } = this.state;
    if (text) {
      this.setState({ loading: true });
      channel.sendMessage(String(text).trim());
      this.setState({ text: '', loading: false });
    }
  };
  //Updating the messages in the state
  updateMessage = (event: any) => {
    event.preventDefault();
    this.setState({ text: event.target.value });
  };

  render() {
    const { loading, text, messages, channel, email, room, EnrolleeIdentity, showMap, isTyping } = this.state;
    const showModal = this.state.showModalInfo > 0 ? true : false;
    const msg =
      this.state.alertType == 'idle'
        ? alerts.IDLE_CHAT
        : this.state.alertType == 'idlelong'
        ? alerts.IDLE_CHAT_LONGTIME
        : this.state.alertType == 'exit'
        ? alerts.END_CHAT
        : this.state.alertType == 'error'
        ? alerts.ERROR
        : this.state.alertType == 'participantLeft'
        ? alerts.MEMBER_LEFT
        : this.state.alertType == 'exception'
        ? alerts.CHANNEL_EXCEPTION
        : alerts.ERROR;

    const modal =
      this.state.showModalInfo > 0 ? (
        <InfoModal
          enrolleeImg={''}
          show={showModal}
          modelType={this.state.showModalInfo}
          modalClose={this.modalClose}
          modalYes={this.modalConfirm}
          msg={msg}
          notice_msg={''}
        />
      ) : null;

    let toggleclass = showMap ? 'locationIcnOn cursor-pointer' : 'locationIcn cursor-pointer';
    let enrolleeName = localStorage.getItem('enrolleeName');
    return (
      <>
        {modal}
        {this.state.NoteModel && (
          <NoteModel modalClose={this.toggleNote} ChannelID={this.state.ChannelID} CallID={this.state.CallID} />
        )}
        <div className="d-flex ChatWindow">
          <div className="chatLoader">{this.state.showLoader ? <PageLoader show={true} /> : null}</div>

          <div className="row px-0 chatHeading">
            <div className="col cursor-pointer" onClick={() => this.showAlert()}>
              End Chat
            </div>
            <div className="col text-center">
              <h5 className="mb-0 p-0">Chat</h5>
            </div>
            <div className="col text-right">
              <LocationOnIcon
                className={toggleclass}
                onClick={() => {
                  this.showMapButton();
                }}
              />
            </div>
          </div>

          <div className="h-100 position-relative minh-305">
            <div className="p-3 chatcontainer h-100 w-100 d-block position-absolute" ref={this.scrollDiv}>
              {messages &&
                messages.map((message: any) => (
                  <ChatItem key={message.index} message={message} EnrolleeIdentity={EnrolleeIdentity} />
                ))}
            </div>
          </div>
          <div className="col cursor-pointer"></div>
          {isTyping ? <div className="col cursor-pointer">{enrolleeName} is typing...</div> : null}
          <div className="px-3 py-2 flex-shrink-1 bd-highlight">
            <Box className="d-flex">
              <InputBase
                className="chatInput"
                placeholder="Type a message"
                value={text}
                disabled={!channel}
                onChange={this.updateMessage}
                onKeyDown={this.handleEnter}
              />

              <IconButton onClick={this.sendMessage} disabled={!channel}>
                <SendIcon />
              </IconButton>
            </Box>
          </div>
          {this.state.showMap && <App showMapButton={this.showMapButton} />}
          {this.state.sessionout && (
            <Modal
              show={true}
              animation={false}
              className="enrollee-popup"
              aria-labelledby="contained-modal-title-vcenter"
              centered={true}
            >
              <Modal.Body>
                {' '}
                <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
              </Modal.Body>
              <Modal.Footer style={{ paddingTop: '0' }}>
                <Buttons variant="primary" className="btn btn-primary" onClick={this.handleSessionOut}>
                  OK
                </Buttons>
              </Modal.Footer>
            </Modal>
          )}
          {this.state.exception && (
            <Modal
              show={this.state.exception}
              animation={false}
              className="enrollee-popup"
              aria-labelledby="contained-modal-title-vcenter"
              centered={true}
            >
              <Modal.Body>
                {' '}
                <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.CHANNEL_EXCEPTION}</h6>
              </Modal.Body>
              <Modal.Footer style={{ paddingTop: '0' }}>
                <Buttons variant="primary" className="btn btn-primary" onClick={this.handleExceptionOK}>
                  OK
                </Buttons>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default ChatScreen;
