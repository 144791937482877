// Check session
export async function checkSession() {
  let status = 0;
  let session_out_status = 0;
  if (localStorage.getItem('user')) {
    const requestOptions = {
      body:
        'session_id=' +
        localStorage.getItem('user') +
        '&filter=obelus&officer_id=' +
        localStorage.getItem('officer_id'),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    await fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/info', requestOptions)
      .then(res =>
        res
          .json()
          .then(data => {
            const result = JSON.parse(JSON.stringify(data));
            status = result.response.status == 'Failed' || !localStorage.getItem('user') ? 1 : 0;
            if (result.response.status === 0) {
              return (session_out_status = 0);
            } else {
              return (session_out_status = 1);
            }
          })
          .catch(error => {
            alert('Unable to connect to the server, please try agin later.');
          })
      )
      .catch(error => {
        alert('Unable to connect to the server, please try agin later.');
      });
  } else {
    session_out_status = 1;
  }

  return session_out_status;
}
