import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });

export const requestForToken = setTokenFound => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VALID_API_KEY,
  })
    .then(currentToken => {
      if (currentToken) {
        setTokenFound(true);
        localStorage.setItem('push_token', currentToken);

        if (localStorage.getItem('user')) {
          let officer_id = localStorage.getItem('officer_id');
          let session_id = localStorage.getItem('user');
          const requestOptions = {
            body: 'session_id=' + session_id + '&officer_id=' + officer_id + '&DeviceToken=' + currentToken,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            method: 'POST',
          };
          return fetch(
            process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/update_device_token',
            requestOptions
          )
            .then(res =>
              res.json().then(
                data => {},
                error => {
                  console.log('Error');
                }
              )
            )
            .catch(error => {
              console.log('Error');
            });
        }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};
