import React, { useEffect, useState } from 'react';

import Pagination from 'react-bootstrap/Pagination';

const PaginationList = ({ ...props }) => {
  const [items, setItems] = useState<any>([]);
  const [count, setCount] = useState(props.count ?? 0);
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);

  useEffect(() => {
    let p: any[] = [];
    for (
      let number = 1;
      number <= (Math.ceil(count / props.limit) > 0 ? Math.ceil(count / props.limit) : 1);
      number++
    ) {
      p.push(
        <Pagination.Item
          key={number}
          active={number === props.active}
          onClick={() => {
            setActive(number);
            props.setActive(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
      setItems(p);
    }
  }, [count, active]);

  return (
    <div>
      <Pagination>
        <Pagination.Prev
          disabled={props.active === 1}
          onClick={() => {
            setActive(props.active > 1 ? props.active - 1 : props.active);
            props.setActive(props.active > 1 ? props.active - 1 : props.active);
          }}
        />
        {items}
        <Pagination.Next
          disabled={props.active === Math.ceil(count / props.limit)}
          onClick={() => {
            setActive(props.active === Math.ceil(count / props.limit) ? props.active : props.active + 1);
            props.setActive(props.active === Math.ceil(count / props.limit) ? props.active : props.active + 1);
          }}
        />
      </Pagination>
    </div>
  );
};
export default PaginationList;
