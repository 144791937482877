/**
 * @file   InfoModal.tsx
 * @brief  Modal componenent
 * @date   Mar , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { NoteInfoModal } from './NoteInfoModal';

type MyProps = {
  callstate: String;
};
type MyState = {
  connectedStatus: Number;
  showModal: Boolean;
};

export class CallEndNote extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      connectedStatus: 0,
      showModal: false,
    };
    this.handleShow();
  }

  handleShow = () => {
    if (this.props.callstate === 'connected') {
      if (this.state.connectedStatus === 0) {
        this.setState({ connectedStatus: 1 });
        return false;
      }
    } else if (this.state.connectedStatus == 1) {
      if (this.props.callstate === 'disconnected') {
        if (sessionStorage.getItem('note_added') == '1') {
          return false;
        } else {
          return true;
        }
      }
    }

    return false;
  };

  // Modal render function
  render() {
    return (
      <div>
        <NoteInfoModal callstate={'disconnected'} modalClose={() => {}} showStatus={true} />
        {}
        ''
        {}
      </div>
    );
  }
}
