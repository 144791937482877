/**
 * @file   Chatitem.tsx
 * @brief  Chat item component
 * @date   August , 2021
 * @author ZCO Engineer
 * @copyright (c) 2021, ZCO
 */
import React from 'react';

interface Props {
  message: any;
  EnrolleeIdentity: string;
}

class ChatItem extends React.Component<Props> {

  time= (date:any) => {
    var d = new Date(date);
    var n = d.toLocaleTimeString(); 
    var string = d.toLocaleString('en-US',{ hour: 'numeric', minute: '2-digit' });
    return string;
  }


  render() {
    const { message, EnrolleeIdentity } = this.props;

    const isOwnMessage = message.author !== EnrolleeIdentity;

    let chatStyle = 'chat1';
    let chatStyle1 = 'flex-row';
    if (isOwnMessage === true) {
      chatStyle = 'chat2';
      chatStyle1 = 'flex-row-reverse';
    }

    return (
      <div className={`d-flex ${chatStyle1}`}>
        <div className={`chat p-3 ${chatStyle}`}>
          <div className="row mx-15 p-0">
            <div className="col chatperson">{message.author}</div>
            <div className="col text-right chattime">
              {this.time(message.dateCreated)}
            </div>
          </div>
          <div className="chatmsg">{message.body}</div>
        </div>
      </div>
    );
  }
}

export default ChatItem;
