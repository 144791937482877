import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function MenuBar() {
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const { connect } = useVideoContext();

  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      const token = localStorage.getItem('access_token');
      if (token) {
        connect(token);
      }
    }
  }, []);

  return (
    <div>
      {' '}
      <span className="display-none">{roomName}</span>{' '}
    </div>
  );
}
