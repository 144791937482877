import EventEmitter from 'events';
import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Callback } from '../../../types';
import CallAlert from '../../css/audio/chat_ring_tone_5min.wav';
let audio = new Audio(CallAlert);
const { Logger } = Video;
const logger = Logger.getLogger('twilio-video');
// @ts-ignore
window.TwilioVideo = Video;
audio.addEventListener(
  'ended',
  function() {
    audio.currentTime = 0;
    audio.play();
  },
  false
);
export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const disconnectHandlerRef = useRef<() => void>(() => {});
  const localTracksRef = useRef<LocalTrack[]>([]);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);
  const originalFactory = logger.methodFactory;

  logger.methodFactory = function(methodName, level, loggerName) {
    const method = originalFactory(methodName, level, loggerName);

    return function(datetime, logLevel, component, message, data) {
      method(datetime, logLevel, component, message, data);
      if (message === 'event' && data.group === 'signaling') {
        if (data.name === 'waiting') {
          console.warn("Twilio's signaling server is busy.");
        } else if (data.name === 'connecting') {
          console.log("Connecting to Twilio's signaling server.");
        } else if (data.name === 'open') {
          console.log("Connected to Twilio's signaling server.");
        } else if (data.name === 'closed') {
          if (data.level === 'error') {
            const {
              payload: { reason },
            } = data;
            console.error("Connection to Twilio's signaling server abruptly closed:", data.reason);
          } else {
            console.log("Connection to Twilio's signaling server closed.");
          }
        }
      }
    };
  };
  logger.setLevel('debug');
  const connect = useCallback(
    token => {
      setIsConnecting(true);
      // curl -X POST https://api.twilio.com/2010-04-01/Accounts/$TWILIO_ACCOUNT_SID/Tokens.json \
      // -u $TWILIO_ACCOUNT_SID: $TWILIO_AUTH_TOKEN
      // Replicate curl API call using localstorage credentials.
      //  chain promise to typical Video.connect() API
      let sid = localStorage.getItem('twilioAccount');
      let auth_token = localStorage.getItem('twilioAPISecret');
      let encoded = btoa(`${sid}:${auth_token}`);

      return fetch(`https://api.twilio.com/2010-04-01/Accounts/${sid}/Tokens.json`, {
        mode: 'cors',
        method: 'POST',
        headers: { Authorization: `Basic ${encoded}`, 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .then(
          data => {
            var ice_servers = data.ice_servers;
            audio.play();
            return Video.connect(token, {
              ...options,
              tracks: [],
              iceServers: ice_servers,
              networkQuality: {
                local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
                remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
              },
            }).then(
              newRoom => {
                setRoom(newRoom);
                newRoom.localParticipant.setNetworkQualityConfiguration({
                  local: 3,
                  remote: 3,
                });
                newRoom.addListener('participantConnected', () => {
                  audio.pause();
                });
                newRoom.once('disconnected', () => {
                  // Reset the room only after all other `disconnected` listeners have been called.
                  setTimeout(() => setRoom(new EventEmitter() as Room));
                  window.removeEventListener('beforeunload', disconnectHandlerRef.current);
                  //window.location.href="/EnrolleeList";
                  // localStorage.setItem('message', 'This call will be ended.');
                  onError('This call will be ended.');
                  audio.pause();
                });

                // @ts-ignore
                window.twilioRoom = newRoom;

                localTracksRef.current.forEach(track =>
                  // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
                  // However, tracks must be published manually in order to set the priority on them.
                  // All video tracks are published with 'low' priority. This works because the video
                  // track that is displayed in the 'MainParticipant' component will have it's priority
                  // set to 'high' via track.setPriority()
                  newRoom.localParticipant.publishTrack(track, {
                    priority: track.kind === 'video' ? 'low' : 'standard',
                  })
                );

                disconnectHandlerRef.current = () => newRoom.disconnect();
                setIsConnecting(false);

                // Add a listener to disconnect from the room when a user closes their browser
                window.addEventListener('beforeunload', disconnectHandlerRef.current);
              },
              error => {
                audio.pause();
                onError(error);
                setIsConnecting(false);
              }
            );
          },
          error => {
            audio.pause();
            onError(error);
            setIsConnecting(false);
          }
        );
    },
    [options, onError]
  );

  return {
    room,
    isConnecting,
    connect,
  };
}
