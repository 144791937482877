/**
 * @file   InfoModal.tsx
 * @brief  Modal componenent
 * @date   Mar , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  enrolleeImg: string;
  show: boolean;
  modalClose(status: number): void;
  modalYes(modalType: number): void;
  msg: string;
  modelType: number;
  notice_msg: string;
}

interface State {
  showStatus: boolean;
}

export class InfoModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showStatus: this.props.show,
    };
  }

  // Handles modal close
  handleClose = () => {
    this.setState({
      showStatus: false,
    });
    this.props.modalClose(0);
  };

  // Handles modal close
  handleYes = () => {
    this.setState({
      showStatus: false,
    });
    if (this.props.modelType < 3 || this.props.modelType >= 5) {
      this.props.modalYes(this.props.modelType);
    } else {
      this.props.modalClose(0);
    }
  };

  // Modal render function
  render() {
    const modalBody =
      this.props.modelType === 1 ? (
        <div className="enr-list-pop-tb">
          <img src={this.props.enrolleeImg} alt="" />
        </div>
      ) : null;
    return (
      <Modal
        show={this.state.showStatus}
        onHide={this.handleClose}
        animation={false}
        className="enrollee-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{this.props.msg}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          {(this.props.modelType < 3 || this.props.modelType >= 8) && (
            <Button variant="outline-primary" className="btn-outline-primary" onClick={this.handleClose}>
              {this.props.modelType == 9 ? 'No' : 'Cancel'}
            </Button>
          )}
          {this.props.modelType == 5 && (
            <Button variant="outline-primary" className="btn-outline-primary" onClick={this.handleClose}>
              Exit
            </Button>
          )}

          <Button variant="primary" className="btn-primary" onClick={this.handleYes}>
            {this.props.modelType < 3 || this.props.modelType == 9
              ? 'Yes'
              : this.props.modelType == 5
              ? 'Continue'
              : 'Ok'}
          </Button>
          <Modal.Title>
            <div style={{ fontSize: '0.95rem' }} className="text-center">
              {this.props.notice_msg}
            </div>
          </Modal.Title>
        </Modal.Footer>
      </Modal>
    );
  }
}
