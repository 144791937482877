/**
 * @file   AppointmentAddEdit.tsx
 * @brief  Appointment add/edit component
 * @date   November , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TimeField from 'react-simple-timefield';
import DateRange from '@material-ui/icons/DateRange';
import Schedule from '@material-ui/icons/Schedule';
import { SearchEnrollee } from './Actions';
import { PostAppointment } from './Actions';
import { PageLoader } from '../common/Loader';
import { Button as Buttons } from 'react-bootstrap';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

interface IAppointment {
  duration: number;
  DateTime: any;
  Description: string;
}
const AppointmentAddEdit = ({ ...props }) => {
  const [appointment, setAppointment] = useState<IAppointment>();
  const [duration, setDuration] = useState<string>('01:00');
  const [description, setDescription] = useState<string>('');
  const [showLoader, setLoader] = useState<boolean>(false);
  const [adderror, setAddError] = useState<boolean>(false);
  const [noRecords, setNoRecords] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [selectedEntrollee, setSelectedEntrollee] = React.useState<any>('');
  const [errors, setErrors] = React.useState<any>([]);
  const [timeTocheck, setTimetoCheck] = React.useState<number>(0);
  const [filterOptions, setFilterOptions] = React.useState<any>([]);
  const [isClear, setIsClear] = useState<boolean>(false);
  useEffect(() => {
    if (props.appointment !== null && props.appointment !== undefined) {
      setAppointment(props.appointment);
      if (props.appointment.duration) {
        var hours = props.appointment.duration / 60;
        var rhours: any = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes: any = Math.round(minutes);
        if (rhours.toString().length == 1) {
          rhours = '0' + rhours.toString();
        }
        if (rminutes.toString().length == 1) {
          rminutes = '0' + rminutes.toString();
        }
        setDuration(rhours + ':' + rminutes);
      }
      setTimetoCheck(
        moment(new Date())
          .subtract(1, 'minutes')
          .unix()
      );
      setStartDate(moment.unix(props.appointment.DateTime).toDate());
      setStartTime(moment.unix(props.appointment.DateTime).toDate());
      setDescription(props.appointment.Description);
      setSelectedEntrollee({
        value: props.appointment.enrolleeID,
        label: props.appointment.enrolleeName,
      });
    }
  }, [props.appointment]);
  useEffect(() => {
    if (props.action === 'Add') {
      setTimetoCheck(
        moment(new Date())
          .subtract(1, 'minutes')
          .unix()
      );
      if (props.defaultDate !== null && props.defaultDate !== undefined) {
        if (moment(props.defaultDate).unix() < timeTocheck) {
          setStartDate(new Date());
          setStartTime(new Date());
        } else {
          setStartDate(props.defaultDate);
          setStartTime(
            moment
              .unix(props.defaultDate)
              .startOf('day')
              .toDate()
          );
        }
      } else {
        setStartDate(new Date());
        setStartTime(new Date());
      }

      setDuration('01:00');
      setDescription('');
      setSelectedEntrollee(null);
      setSearchKey('');
      setFilterOptions([]);
      setIsClear(false);
    }
  }, [props.action]);
  const handleEnrolleChange = (event: any) => {
    if (event.target.value) setIsClear(true);
    else setIsClear(false);
    setSearchKey(event.target.value);
    var newState = Object.assign({}, errors);
    if (props.AppointmentNav == 'Sub' && !event.target.value) {
      newState.enrollee = 'An Enrollee should be selected to add this appointment.';
    } else {
      newState.enrollee = '';
    }
    setErrors(newState);
  };

  const handleAddAppointment = async () => {
    var date = moment(startDate).startOf('day');
    var timehr = moment(startTime).format('HH');
    var timemin = moment(startTime).format('mm');
    date.add(timehr, 'hours').add(timemin, 'minutes');
    var error = false;
    var newState = Object.assign({}, errors);
    if (props.AppointmentNav == 'Sub' && !selectedEntrollee) {
      error = true;
      newState.enrollee = 'An Enrollee should be selected to add this appointment.';
    }
    if (!description) {
      error = true;
      newState.description = 'Enter event description.';
    }
    if (moment(date).unix() < timeTocheck) {
      error = true;
      newState.date = 'Appointment time should be a future time.';
    } else {
      newState.date = ' ';
    }
    setErrors(newState);

    if (error) {
      return;
    }

    var entrolleid;
    if (props.AppointmentNav == 'Sub') {
      entrolleid = selectedEntrollee.value;
    } else {
      entrolleid = sessionStorage.getItem('session_enrollee_id');
    }
    var durationArr = duration.split(':');
    var durationMinutes = parseInt(durationArr[0]) * 60 + parseInt(durationArr[1]);
    var id = '';
    if (props.appointment !== null && props.appointment !== undefined) id = props.appointment.appointmentID;
    var appointmentVal = {
      id: id,
      description: description,
      entrolleid: entrolleid,
      timestamp: moment(date).unix(),
      duration: durationMinutes,
      duration_type: 'minute',
    };
    setLoader(true);
    var result: any = await PostAppointment(appointmentVal);
    if (result == 'Successful') {
      props.setIsAdd(true);
      props.appointment = null;
      handleAddClose();
    } else if (result == 'Error') {
      setAddError(true);
    } else if (result == 'Session Out') {
      props.setSessionOut(true);
    }
    setLoader(false);
  };
  // load options using API call
  const loadOptions = async () => {
    if (searchKey.length < 3) return;
    setSelectedEntrollee(null);

    if (searchKey) {
      setIsClear(true);
    } else {
      setIsClear(false);
    }
    setLoader(true);
    const data: any = await SearchEnrollee(searchKey);
    setLoader(false);
    var filterValues: any = [];
    if (data.status == 0) {
      data.enrollees.map((item: any) => {
        filterValues.push({
          value: item.enrollee_id,
          label: item.enrollee_name,
        });
      });
      if (filterValues.length === 0) {
        setNoRecords(true);
        setSearchKey('');
      }
    } else if (data == 'Session Out') {
      props.setSessionOut(true);
      return;
    }
    setFilterOptions(filterValues);
  };

  const handleClear = () => {
    setSearchKey('');
    setFilterOptions([]);
    setIsClear(false);
    setSelectedEntrollee(null);
  };
  const handleDateChange = (date: any) => {
    setStartDate(date);
  };
  //Enable searching when taping enter key
  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      loadOptions();
    }
  };
  const handleTimeChange = (date: any) => {
    setTimetoCheck(moment(new Date()).unix());
    setStartTime(date);
    var datechk = moment(startDate).startOf('day');
    var timehr = moment(date).format('HH');
    var timemin = moment(date).format('mm');
    datechk.add(timehr, 'hours').add(timemin, 'minutes');
    var newState = Object.assign({}, errors);
    if (moment(datechk).unix() > timeTocheck) {
      newState.date = '';
    } else {
      newState.date = 'Appointment time should be a future time.';
    }
    setErrors(newState);
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
    var newState = Object.assign({}, errors);
    if (event.target.value) {
      newState.description = '';
    } else {
      newState.description = 'Enter event description.';
    }
    setErrors(newState);
  };
  const handleDurationChange = (event: any) => {
    setDuration(event.target.value);
    var newState = Object.assign({}, errors);
    if (event.target.value !== '00:00') {
      newState.duration = '';
    } else {
      newState.duration = 'Enter event duration.';
    }
    setErrors(newState);
  };
  const handleAddClose = () => {
    setStartDate(new Date());
    setStartTime(new Date());
    setDuration('01:00');
    setDescription('');
    var newState = Object.assign({}, errors);
    newState.enrollee = '';
    newState.description = '';
    newState.date = '';
    newState.duration = '';
    setErrors(newState);
    props.appointment = null;
    props.handleAddClose();
  };
  //Handle session out ok
  const handleError = () => {
    setAddError(false);
    handleAddClose();
  };
  //Handle no records
  const handleNoRecords = () => {
    setNoRecords(false);
  };

  const handleEnrolleClick = (item: any) => {
    setSelectedEntrollee(item);
    setSearchKey(item.label);
    setFilterOptions([]);
  };
  return (
    <Modal size="lg" className="calenderPopup" show={props.showAdd} onHide={handleAddClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {/* Popup 3 */}

        <div className=" pb-3 dateTimeWrap">
          {props.AppointmentNav == 'Sub' && props.action === 'Add' && (
            <div className="mr-1 mb-2 ">
              {/* <AsyncSelect
                name=""
                loadOptions={loadOptions}
                onChange={handleChange}
                isSearchable={true}
                isClearable={true}
                onInputChange={handleInputChange}
                value={selectedEntrollee}
                placeholder="Search by enrollee name..."
              /> */}
              <div className="mb-2 searchDiv">
                <Form.Control
                  placeholder="Search by enrollee name..."
                  value={searchKey}
                  onChange={handleEnrolleChange}
                  onKeyDown={handleEnter}
                />
                {filterOptions.length > 0 ? (
                  <div className="searchPopup">
                    {filterOptions.map((item: any, index: any) => (
                      <div style={{ cursor: 'pointer' }} key={index} onClick={() => handleEnrolleClick(item)}>
                        {item.label}
                      </div>
                    ))}
                  </div>
                ) : null}

                {isClear ? <ClearIcon onClick={handleClear} /> : null}
                <SearchIcon onClick={loadOptions} className="" />
              </div>
              <span className="error">{errors['enrollee']}</span>
            </div>
          )}
          <div className="d-flex">
            <div className="mr-1 mb-1 dateTimeField">
              {/* <Form.Control placeholder="Date" type="" id="" aria-describedby="" /> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={startDate}
                  placeholder="Date"
                  onChange={date => handleDateChange(date)}
                  minDate={new Date()}
                  format="MMMM-dd-yyyy"
                  keyboardIcon={<DateRange />}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="mr-1 mb-1 ml-2 dateTimeField">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  placeholder="Time"
                  mask="__:__ _M"
                  value={startTime}
                  onChange={date => handleTimeChange(date)}
                  keyboardIcon={<Schedule />}
                  style={{ maxWidth: '150px' }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="mr-1 mb-1 fnt14 ">
              <div className="p-2 float-left">Duration (HH:MM) </div>
              <TimeField
                style={{ width: '120px', float: 'left' }}
                value={duration}
                onChange={handleDurationChange}
                input={<Form.Control placeholder="" type="" id="" aria-describedby="" />}
                colon=":"
              />
              <div className="error  pl-2 pt-1">{errors['duration']}</div>
            </div>
          </div>
          <span className="error">{errors['date']}</span>
        </div>
        <p>
          <Form.Control
            as="textarea"
            placeholder=""
            style={{ height: '100px' }}
            value={description}
            onChange={handleDescriptionChange}
          />
          <span className="error">{errors['description']}</span>
        </p>
        {showLoader ? <PageLoader show={true} /> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="mb-3" onClick={handleAddAppointment}>
          Save
        </Button>
      </Modal.Footer>
      {adderror && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>Error on save.</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Buttons variant="primary" className="btn btn-primary" onClick={handleError}>
              OK
            </Buttons>
          </Modal.Footer>
        </Modal>
      )}
      {noRecords && (
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
        >
          <Modal.Body>
            {' '}
            <h6 style={{ textAlign: 'center', color: 'black' }}>No records found.</h6>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Buttons variant="primary" className="btn btn-primary" onClick={handleNoRecords}>
              OK
            </Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </Modal>
  );
};
export default AppointmentAddEdit;
