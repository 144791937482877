import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from '../../firebase';
import { Modal } from 'react-bootstrap';
import { Button as Buttons } from 'react-bootstrap';
import NotificatioAlert from '../css/audio/notification.wav';
let audio = new Audio(NotificatioAlert);

const Notification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  const [isReceivedPush, setIsReceivedPush] = useState(false);

  const handleOK = () => {
    localStorage.setItem('is_voice_mail', 'true');
    window.location.href = '/EnrolleeList';
    setIsReceivedPush(false);
    audio.pause();
  };

  useEffect(() => {
    if (notification?.title) {
      setIsReceivedPush(true);
      audio.play();
    }
  }, [notification]);

  requestForToken(setTokenFound);

  onMessageListener()
    .then(payload => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch(err => console.log('failed: ', err));

  return (
    <Modal
      show={isReceivedPush}
      animation={false}
      className="enrollee-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header style={{ paddingBottom: '0' }}>
        <Modal.Title style={{ width: '100%' }}>{notification?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        <h6 style={{ textAlign: 'center', color: 'black' }}>{notification?.body}</h6>
      </Modal.Body>
      <Modal.Footer style={{ paddingTop: '0' }}>
        <Buttons variant="primary" className="btn btn-primary" onClick={handleOK}>
          OK
        </Buttons>
      </Modal.Footer>
    </Modal>
  );
};

export default Notification;
