import React, { useState } from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled } from '@material-ui/core/styles';
import LocationIcon from '@material-ui/icons/LocationOn';

import MainParticipant from '../MainParticipant/MainParticipant';
// import LocalAudioLevelIndicator from '../MenuBar/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import ToggleFullscreenButton from '../MenuBar/ToggleFullScreenButton/ToggleFullScreenButton';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import App from '../common/Map';
import IconButton from '@material-ui/core/IconButton';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
  gridTemplateAreas: '". participantList"',
  [theme.breakpoints.down('xs')]: {
    gridTemplateAreas: '"participantList" "."',
    gridTemplateColumns: `auto`,
    gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight + 12}px) ${theme.sidebarMobileHeight + 6}px`,
    gridGap: '6px',
  },
}));

export default function Room() {
  const [showMap, setShowMap] = useState(false);

  const showMapButton = () => {
    setShowMap(!showMap);
  };

  let toggleclass = showMap ? 'locationtoggleOn' : 'locationtoggle';
  return (
    <div>
      <Container>
        <div className="col-12 main-video">
          <div className="container position-relative">
            <div className="top-controls">
              <ToggleFullscreenButton />

              <IconButton
                className={toggleclass}
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                <LocationIcon />
              </IconButton>
            </div>
            <MainParticipant />
            <div className="small_thumb">
              <ParticipantStrip />
            </div>
          </div>
          {/*!!showMap && (
            <span className="closebtn_outer">
              <CancelRoundedIcon
                className="closebtn"
                onClick={() => {
                  showMapButton()
                }}
              />
            </span>
              )*/}
          {!!showMap && <App showMapButton={showMapButton} />}
        </div>
      </Container>
    </div>
  );
}
