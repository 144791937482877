/**
 * @file   List.tsx
 * @brief  Enrollee list component
 * @date   May , 2020
 * @author ZCO Engineer
 * @copyright (c) 2020, ZCO
 */
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { InfoModal } from './InfoModel';
import { Menu } from './Menu';
import EnrollEdit from './EnrollEdit';
import VoiceMessage from '../Voicemessage/VoiceMessage';
import ScheduleUpdate from './ScheduleUpdate';
import Appointments from '../Appointments/Appointments';
import { LocationModel } from './LocationModel';
import { NoteModel } from './NoteModel';
import '../css/style.css';
import logoutLogo from '../css/images/icn-logt.png';
import loggedimg from '../css/images/user.svg';
import voiceIcon from '../css/images/ShadowVmail_green.png';
import calenderGreenIcon from '../css/images/calenderGreenIcon.png';
import * as alerts from '../../messages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PageLoader } from '../common/Loader';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { checkSession } from '../common/SessionCheck';
import ChatScreen from '../Chat/ChatScreen';
import { SMSModel } from './SMSModel';
import Badge from '@material-ui/core/Badge';
import { GetVoicemails } from '../Voicemessage/Actions';
import { Modal } from 'react-bootstrap';
import { Button as Buttons } from 'react-bootstrap';
import { logout } from '../../functions';
import { useParams } from 'react-router-dom';

interface ListState {
  enrollees: Array<any>;
  enrolleeId: string;
  enrolleeImg: string;
  enrolleeName: string;
  enrolleenumber: string;
  error: boolean;
  filter: string;
  loadedEnrollees: boolean;
  showLoader: boolean;
  showModalInfo: number;
  startCall: boolean;
  videoError: boolean;
  session_out_status: number;
  menu: number;
  menuType: string;
  locationModel: boolean;
  NoteModel: boolean;
  SMSModel: boolean;
  unreadCount: number;
  VoicemessageData: any;
  VoicemessageNav: string;
  AppointmentNav: string;
  VoicemessageLoading: boolean;
  showClear: boolean;
  sessionout: boolean;
  defaultSearch: string;
}
interface MutableRefObject<T> {
  current: T | null;
}

class List extends React.Component<any, ListState, MutableRefObject<null>> {
  private video: any;
  private searchInput: any;
  constructor(props: {}) {
    super(props);
    if (this.props.match.params.SessionToken) {
      localStorage.setItem('user', this.props.match.params.SessionToken);
      localStorage.setItem('username', this.props.match.params.UserName);
      localStorage.setItem('officer_id', this.props.match.params.OfficerID);
      this.getContactType();
    }
    this.state = {
      enrollees: [],
      enrolleeId: '',
      enrolleeImg: '',
      enrolleeName: '',
      enrolleenumber: '',
      error: false,
      filter: '',
      loadedEnrollees: true,
      showLoader: false,
      showModalInfo: 0,
      startCall: false,
      videoError: false,
      session_out_status: 1,
      menu: 0,
      menuType: '',
      locationModel: false,
      NoteModel: false,
      SMSModel: false,
      unreadCount: 0,
      VoicemessageData: [],
      VoicemessageNav: '',
      AppointmentNav: '',
      VoicemessageLoading: false,
      showClear: false,
      sessionout: false,
      defaultSearch: '',
    };

    this.validateSession();
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    this.fetchVoiceMailAPI();
    if (localStorage.getItem('storeData') === '1') {
      localStorage.setItem('storeData', ' ');
      this.handleSelectFromLocalStorage(sessionStorage.getItem('session_enrollee_id') || '');
      if (localStorage.getItem('searchkey') !== null) {
        this.setState({
          defaultSearch: localStorage.getItem('searchkey') || '',
        });
      }
    }
    if (localStorage.getItem('is_voice_mail') == 'true') {
      this.setState({
        menu: 5,
        VoicemessageNav: 'Sub',
      });
    } else if (localStorage.getItem('is_appointment') == 'true') {
      this.setState({
        menu: 6,
        AppointmentNav: 'Sub',
      });
    }
  }

  getContactType = async () => {
    const requestOptions = {
      body: 'session_id=' + localStorage.getItem('user') + '&officer_id=' + localStorage.getItem('officer_id'),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(
      process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/refresh_contacttypes',
      requestOptions
    ).then(res =>
      res.json().then(
        data => {
          const result = JSON.parse(JSON.stringify(data));
          if (data.response.status == 'Successful') {
            localStorage.setItem('ContactTypes', JSON.stringify(result.response.ContactTypes));
          } else if (data.response.errorCode == 5) {
            this.setState({ sessionout: true });
          } else {
            logout();
          }
        },
        error => {
          this.setState({ error });
        }
      )
    );
  };
  fetchVoiceMailAPI = async () => {
    let VoicemessageList = await GetVoicemails();
    if (VoicemessageList == 'Session Out') {
      this.setState({ sessionout: true });
      return;
    }
    this.setState({
      VoicemessageData: VoicemessageList,
    });
    if (VoicemessageList && VoicemessageList.voicemails && VoicemessageList.voicemails.length > 0) {
      var unread = VoicemessageList.voicemails.filter((x: any, i: any) => x.readFlag === 'false').length;
      this.setState({
        unreadCount: unread,
      });
    }
  };
  //session validation
  validateSession = () => {
    var that = this;
    checkSession().then(function(responce: number) {
      that.setState({ session_out_status: responce });
      if (responce == 1) {
        that.setState({ sessionout: true });
      }
    });
  };

  init_devices = async () => {
    let constraints = { video: true };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
  };
  //Enrollees search functionality handling here
  search = (filterData: string) => {
    localStorage.setItem('is_voice_mail', 'false');
    localStorage.setItem('is_appointment', 'false');

    if (localStorage.getItem('user') == null) {
      logout();
    }

    const requestOptions = {
      body:
        'session_id=' +
        localStorage.getItem('user') +
        '&filter=' +
        filterData +
        '&officer_id=' +
        localStorage.getItem('officer_id') +
        '&version=v2',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
    };
    return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/info', requestOptions).then(res =>
      res.json().then(
        data => {
          const result = JSON.parse(JSON.stringify(data));
          if (result.response.status === 0) {
            this.setState({
              enrollees: result.response.enrollees,
              loadedEnrollees: true,
            });
            localStorage.setItem('enrollees', JSON.stringify(result.response.enrollees));
            localStorage.setItem('twilioAccount', result.response.twilioAccount);
            localStorage.setItem('twilioAPIKey', result.response.twilioAPIKey);
            localStorage.setItem('twilioAPISecret', result.response.twilioAPISecret);
          } else if (data.response.errorCode == 5) {
            this.setState({ sessionout: true });
          } else {
            logout();
          }
        },
        error => this.setState({ error })
      )
    );
  };
  //Method to show menu screen while tap an entrolee
  handleSelect = async (id: string) => {
    var enrollee = id.split('~');
    await this.search(this.searchInput.current.value);
    let enrolleeData = await this.state.enrollees.find(o => o.enrollee_id === enrollee[0]);

    if (enrolleeData) {
      this.setState({
        enrolleeId: enrolleeData.enrollee_id,
        enrolleeName: enrolleeData.enrollee_name,
        enrolleeImg: enrolleeData.imageurl ? this.get_profile_image(enrolleeData.imageurl) : loggedimg,
        enrolleenumber: enrolleeData.phone_number
          ? enrolleeData.phone_number.substr(enrolleeData.phone_number.length - 10)
          : '',
        menu: 1,
      });
    }
    sessionStorage.removeItem('session_enrollee_id');
    sessionStorage.setItem('session_enrollee_id', enrolleeData.enrollee_id);
    localStorage.setItem(
      'phone_number',
      enrolleeData.phone_number ? enrolleeData.phone_number.substr(enrolleeData.phone_number.length - 10) : ''
    );
    localStorage.setItem('phone_type', enrolleeData.phone_type);
    localStorage.setItem('language', enrolleeData.language);
    localStorage.setItem('hearing_impaired', enrolleeData.hearing_impaired);
    localStorage.setItem('mobile_app_token', enrolleeData.mobile_app_token);
    localStorage.setItem('enrolleeName', enrolleeData.enrollee_name);
  };

  //Method to show menu screen while tap an entrolee
  handleSelectFromLocalStorage = async (id: string) => {
    if (localStorage.getItem('enrollees') !== null) {
      this.setState({
        enrollees: JSON.parse(localStorage.getItem('enrollees') || '{}'),
        loadedEnrollees: true,
      });
      let enrolleeData = await JSON.parse(localStorage.getItem('enrollees') || '{}').find(
        (o: any) => o.enrollee_id === id
      );
      if (enrolleeData) {
        this.setState({
          enrolleeId: enrolleeData.enrollee_id,
          enrolleeName: enrolleeData.enrollee_name,
          enrolleeImg: enrolleeData.imageurl ? this.get_profile_image(enrolleeData.imageurl) : loggedimg,
          enrolleenumber: enrolleeData.phone_number
            ? enrolleeData.phone_number.substr(enrolleeData.phone_number.length - 10)
            : '',
          menu: 1,
        });
        sessionStorage.removeItem('session_enrollee_id');
        sessionStorage.setItem('session_enrollee_id', enrolleeData.enrollee_id);
        localStorage.setItem(
          'phone_number',
          enrolleeData.phone_number ? enrolleeData.phone_number.substr(enrolleeData.phone_number.length - 10) : ''
        );
        localStorage.setItem('phone_type', enrolleeData.phone_type);
        localStorage.setItem('language', enrolleeData.language);
        localStorage.setItem('hearing_impaired', enrolleeData.hearing_impaired);
        localStorage.setItem('mobile_app_token', enrolleeData.mobile_app_token);
        localStorage.setItem('enrolleeName', enrolleeData.enrollee_name);
      }
    }
  };

  //Method to handle all menu navigations
  handleSelectMenu = (type: string) => {
    let mobile_app_token = localStorage.getItem('mobile_app_token');
    //  alert(mobile_app_token)
    this.setState({
      menuType: type,
    });

    switch (type) {
      case 'Edit':
        this.setState({
          menu: 2,
        });
        break;
      case 'Schedule':
        this.setState({
          showModalInfo: 5,
        });
        break;
      case 'NoSchedule':
      case 'NoScheduletemplate':
      case 'error':
        this.setState({
          showModalInfo: 6,
        });
        break;
      case 'Video':
        if (mobile_app_token == '' || mobile_app_token == 'undefined') {
          this.setState({ showModalInfo: 6, menuType: 'mobile_app_token' });
          return;
        }
        this.setState({
          showModalInfo: 1,
        });
        break;
      case 'Location':
        this.setState({
          locationModel: true,
        });
        break;
      case 'Note':
        this.setState({
          NoteModel: true,
        });
        break;
      case 'Chat':
        if (mobile_app_token == '' || mobile_app_token == 'undefined') {
          this.setState({ showModalInfo: 6, menuType: 'mobile_app_token' });
          return;
        }
        this.setState({
          showModalInfo: 8,
        });
        break;
      case 'SMS':
        this.setState({
          SMSModel: true,
        });
        break;
      case 'Voice':
        this.setState({
          menu: 5,
          VoicemessageNav: 'Main',
        });
        break;
      case 'Appointments':
        this.setState({
          menu: 6,
          AppointmentNav: 'Main',
        });

        break;
    }
  };

  selectVoiceMessage = () => {
    this.setState({ menu: 0 }, () => {
      this.fetchVoiceMailAPI();
      this.setState({
        menu: 5,
        VoicemessageNav: 'Sub',
      });
    });
    localStorage.setItem('is_voice_mail', 'true');
    localStorage.setItem('is_appointment', 'false');
  };
  selectCalendar = () => {
    this.setState({ menu: 0 }, () => {
      this.setState({
        menu: 6,
        AppointmentNav: 'Sub',
      });
    });
    localStorage.setItem('is_voice_mail', 'false');
    localStorage.setItem('is_appointment', 'true');
  };

  //Method for back to the main menu.
  handleBack = (type: number) => {
    this.setState({
      menu: this.state.menu - type,
    });
  };
  //Method fo start the video call and handle other navigations
  modalConfirm = (modalType: number) => {
    if (modalType === 1) {
      const requestOptions = {
        body:
          'session_id=' +
          localStorage.getItem('user') +
          '&officer_id=' +
          localStorage.getItem('officer_id') +
          '&enrollee_id=' +
          this.state.enrolleeId,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
      };
      return fetch(process.env.REACT_APP_API_SERVER_URL + '/api/rest/mobile/officer/start_call', requestOptions).then(
        res =>
          res.json().then(
            data => {
              if (data.response.errorCode == 5) {
                this.setState({ sessionout: true });
                return;
              }
              const result = JSON.parse(JSON.stringify(data));

              this.setState({ startCall: true, showLoader: false });
              localStorage.setItem('room', result.response.roomName);
              localStorage.setItem('access_token', result.response.officerAccessCode);

              sessionStorage.removeItem('session_enrollee_id');
              sessionStorage.setItem('session_enrollee_id', this.state.enrolleeId);

              sessionStorage.removeItem('room_name');
              sessionStorage.setItem('room_name', result.response.roomName);

              sessionStorage.removeItem('note_added');
              localStorage.setItem('storeData', '1');
              window.location.href = `/room/${localStorage.getItem('room')}`;
            },
            error => this.setState({ error })
          )
      );
    }
    if (modalType === 2) {
      logout();
      this.setState({ showLoader: true });
    }
    if (modalType === 3) {
      window.location.reload();
    }
    if (modalType === 5) {
      this.setState({
        showModalInfo: 0,
      });
      this.setState({
        menu: 3,
      });
    }
    if (modalType === 6) {
      this.handleClose(0);
    }
    if (modalType === 8) {
      this.setState({
        showModalInfo: 0,
      });
      this.setState({
        menu: 4,
      });
    }
  };
  //Tggole different popups
  toggle = (status: number) => {
    if (status == 1) {
      this.setState({
        locationModel: false,
      });
    }
    if (status == 2) {
      this.setState({
        NoteModel: false,
      });
    }
    if (status == 3) {
      this.setState({
        SMSModel: false,
      });
    }
  };
  //Closing popus handling here
  handleClose = (status: number) => {
    if (this.state.showModalInfo >= 5) {
      this.setState({ menu: 1 });
    }
    this.setState({
      showModalInfo: status,
    });
  };
  //Logout confirmation
  handleLogout = () => {
    this.setState({
      showModalInfo: 2,
    });
  };
  //To get profile image
  get_profile_image = (filename: string) => {
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let profile_image = filename;

    if (isIEOrEdge == true) {
      let profile_img_extension: any = null;

      profile_image = filename;
      profile_img_extension = filename.split('.').pop();
      profile_image = 'thumb'
        ? (profile_image = filename
            .split('.')
            .slice(0, -1)
            .join('.'))
        : (profile_image = filename);
    }

    return profile_image;
  };
  //Getting search text box value here
  handleChange = (event: any) => {
    this.setState({ enrolleeId: '' });
    this.setState({ filter: this.searchInput.current.value });

    if (this.searchInput.current.value.trim().length > 2) {
      this.setState({ loadedEnrollees: false });
      this.search(this.searchInput.current.value);
      localStorage.setItem('searchkey', this.searchInput.current.value);
    } else {
      this.setState({ loadedEnrollees: true, enrollees: [] });
    }
    this.setState({ menu: 0 });
  };

  handleClear = (event: any) => {
    this.searchInput.current.value = '';
    this.setState({ enrolleeId: '' });
    this.setState({ filter: this.searchInput.current.value });
    localStorage.setItem('searchkey', this.searchInput.current.value);
    localStorage.setItem('enrollees', '');

    if (this.searchInput.current.value.trim().length > 2) {
      this.setState({ loadedEnrollees: false });
      this.search(this.searchInput.current.value);
    } else {
      this.setState({ loadedEnrollees: true, enrollees: [] });
    }
    this.setState({ menu: 0, showClear: false });
  };
  //Enable searching when taping enter key
  handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      this.handleChange('');
    }
  };

  handleTextChange = (event: any) => {
    if (this.searchInput.current.value !== null && this.searchInput.current.value != '')
      this.setState({ showClear: true });
    else this.setState({ showClear: false });
  };
  //Handle session out ok
  handleSessionOut = () => {
    logout();
  };
  render() {
    let enrollees: any = null;
    let selected = '';
    if (this.state.enrollees && this.state.loadedEnrollees) {
      enrollees =
        this.state.enrollees.length > 0 ? (
          this.state.enrollees.map((item, key) => {
            item.enrollee_id.toString() == this.state.enrolleeId.toString()
              ? (selected = ' selected')
              : (selected = '');
            return (
              <div
                className={'list-group-item list-group-item-action cursor-pointer' + selected}
                onClick={this.handleSelect.bind(
                  null,
                  item.enrollee_id +
                    '~' +
                    item.enrollee_name +
                    '~' +
                    item.imageurl +
                    '~' +
                    item.phone_number +
                    '~' +
                    item.phone_type +
                    '~' +
                    item.language +
                    '~' +
                    item.hearing_impaired
                )}
                key={item.enrollee_id}
              >
                <div className="enrollee-image">
                  <img src={item.imageurl ? this.get_profile_image(item.imageurl) : loggedimg} alt="Enrollee" />
                </div>
                <div className="nm-id-wp">
                  <p>{item.enrollee_name}</p>
                  <span>ID : {item.enrollee_id}</span>
                </div>
              </div>
            );
          })
        ) : !this.state.showLoader ? (
          this.state.filter.trim().length > 0 ? (
            <div className="pt-3 text-center mr-4">No records found. Please try again later.</div>
          ) : (
            <div className="pt-3 text-center mr-4"> Use search to look up enrollee.</div>
          )
        ) : null;
    }
    const msg =
      this.state.showModalInfo === 1
        ? alerts.CONFIRM_START_CHAT + this.state.enrolleeName + '?'
        : this.state.showModalInfo === 8
        ? alerts.CHAT_CONFIRMATION + this.state.enrolleeName + '?'
        : this.state.showModalInfo === 2
        ? alerts.CONFIRM_LOGOUT
        : this.state.showModalInfo === 3
        ? alerts.NO_CAMERA_PERMISSION
        : this.state.showModalInfo === 4
        ? alerts.NOTICE_VIDEO
        : this.state.showModalInfo === 5
        ? alerts.CONFIRM_SCHEDULE
        : this.state.showModalInfo === 6
        ? this.state.menuType == 'NoScheduletemplate'
          ? alerts.CONFIRM_NOSCHEDULE_TEMPLATE
          : this.state.menuType == 'NoSchedule'
          ? alerts.CONFIRM_NOSCHEDULE
          : this.state.menuType == 'mobile_app_token'
          ? alerts.MOBILE_TOKEN_VALIDATION_CAHT
          : alerts.ERROR
        : '';

    const notice_msg = this.state.showModalInfo === 1 ? alerts.NOTICE_VIDEO : '';

    const showModal = this.state.showModalInfo > 0 ? true : false;
    const modal =
      this.state.showModalInfo > 0 ? (
        <InfoModal
          enrolleeImg={this.state.enrolleeImg}
          show={showModal}
          modelType={this.state.showModalInfo}
          modalClose={this.handleClose}
          modalYes={this.modalConfirm}
          msg={msg}
          notice_msg={notice_msg}
        />
      ) : this.state.locationModel ? (
        <LocationModel modalClose={this.toggle} />
      ) : this.state.NoteModel ? (
        <NoteModel modalClose={this.toggle} />
      ) : this.state.SMSModel ? (
        <SMSModel modalClose={this.toggle} validateSession={this.validateSession} />
      ) : null;

    return (
      <div>
        {' '}
        {modal}
        {this.state.showLoader ? <PageLoader show={true} /> : null}
        {this.state.session_out_status == 0 ? (
          <div className="container-fluid container-inn-wrap enrollee-wrap">
            <div className="row row-wrap flex-column-reverse flex-md-row">
              <div className="col-lg-3 col-md-4">
                <video
                  autoPlay={true}
                  style={{ display: 'none' }}
                  ref={video => {
                    this.video = video;
                  }}
                  id="videoElement"
                ></video>
                <div className="enrollees-list-search">
                  <FilledInput
                    id="filled-adornment-password"
                    type="text"
                    onKeyDown={this.handleEnter}
                    inputRef={this.searchInput}
                    placeholder="Search"
                    onChange={this.handleTextChange}
                    defaultValue={this.state.defaultSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        {this.state.showClear ? <ClearIcon onClick={this.handleClear} /> : null}
                        <SearchIcon onClick={this.handleChange} />
                      </InputAdornment>
                    }
                  />
                </div>
                <Scrollbars style={{ width: 100, height: 600 }} className="enrollees-list-scroll">
                  {!this.state.loadedEnrollees ? <CircularProgress className="loadingSpinner" /> : null}
                  <div className="list-group enrollee-list">{enrollees}</div>
                </Scrollbars>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="welcome-wrap">
                  <div onClick={this.handleLogout} className="float-right cursor-pointer">
                    <img src={logoutLogo} alt="logout" />
                  </div>
                  <div className="logged_user">{localStorage.getItem('username')}</div>
                  <div className="voicemail" onClick={() => this.selectVoiceMessage()}>
                    <Badge badgeContent={this.state.unreadCount} color="secondary" overlap="rectangular">
                      <img src={voiceIcon} />
                    </Badge>
                  </div>
                  <div className="calenderTopIcon" onClick={() => this.selectCalendar()}>
                    <Badge color="secondary" overlap="rectangular">
                      <img src={calenderGreenIcon} />
                    </Badge>
                  </div>
                  {this.state.menu == 0 && (
                    <div className="welcome-name">
                      <span>Welcome</span>
                      <p>{localStorage.getItem('username')}</p>
                    </div>
                  )}
                  <div className="row w-100">
                    {this.state.menu != 0 && this.state.menu != 5 && this.state.menu != 6 && (
                      <div className="selected_enrollee">
                        <div className="enrollee-image">
                          <img src={this.state.enrolleeImg} alt="Enrollee" />
                        </div>
                        <div className="nm-id-wp">
                          <p>{this.state.enrolleeName}</p>
                          <span>{this.state.enrolleeId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {this.state.menu === 1 && <Menu handleMenu={this.handleSelectMenu} />}
                {this.state.menu === 2 && (
                  <EnrollEdit
                    handleMenu={this.handleSelectMenu}
                    handleBack={this.handleBack}
                    validateSession={this.validateSession}
                  />
                )}
                {this.state.menu === 3 && (
                  <ScheduleUpdate
                    handleMenu={this.handleSelectMenu}
                    handleBack={this.handleBack}
                    validateSession={this.validateSession}
                  />
                )}
                {this.state.menu === 4 && (
                  <ChatScreen validateSession={this.validateSession} handleBack={this.handleBack} />
                )}
                {this.state.menu === 5 && (
                  <VoiceMessage
                    validateSession={this.validateSession}
                    handleBack={this.handleBack}
                    VoicemessageData={this.state.VoicemessageData}
                    enrollee_id={this.state.enrolleeId}
                    VoicemessageNav={this.state.VoicemessageNav}
                    fetchVoiceMailAPI={this.fetchVoiceMailAPI}
                    showLoader={this.state.VoicemessageLoading}
                  />
                )}
                {this.state.menu === 6 && (
                  <Appointments
                    AppointmentNav={this.state.AppointmentNav}
                    handleMenu={this.handleSelectMenu}
                    handleBack={this.handleBack}
                    validateSession={this.validateSession}
                    enrollee_id={this.state.enrolleeId}
                  />
                )}
              </div>
            </div>
            <div className="row pvc-pcy">
              <a href="https://www.shadowtrack.com/about_us/privacy/?doing_wp_cron=1594061874.9618470668792724609375">
                Privacy Policy
              </a>
            </div>
          </div>
        ) : (
          <PageLoader show={true} />
        )}
        {this.state.sessionout && (
          <Modal
            show={true}
            animation={false}
            className="enrollee-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
          >
            <Modal.Body>
              {' '}
              <h6 style={{ textAlign: 'center', color: 'black' }}>{alerts.SESSION_OUT}</h6>
            </Modal.Body>
            <Modal.Footer style={{ paddingTop: '0' }}>
              <Buttons variant="primary" className="btn btn-primary" onClick={this.handleSessionOut}>
                OK
              </Buttons>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}
export default List;
