/**
 * @file   TranscriptionModel.tsx
 * @brief  TranscriptionModel modal componenent
 * @date   June , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import * as React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';

type MyProps = { modalClose(status: number): void; ChannelID?: any; CallID?: any; transcription?: any };
type MyState = {
  showStatus: boolean;
};

export class TranscriptionModel extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showStatus: false,
    };
  }

  // Handles modal close
  handleClose = () => {
    if (this.props.CallID) return false;
    else this.props.modalClose(2);
  };

  // Handles modal close
  handleYes = () => {
    this.props.modalClose(2);
  };

  handleShow = () => {};

  closeButton = () => {
    if (this.props.CallID) return false;
    else return true;
  };

  // Modal render function
  render() {
    return (
      <div>
        <Modal
          show={true}
          animation={false}
          className="enrollee-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered={true}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton={this.closeButton()} style={{ paddingBottom: '0' }}>
            <Modal.Title style={{ width: '100%' }}>Transcription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-dark text-center">
              {this.props.transcription != '' ? this.props.transcription : 'Transcription not available.'}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ paddingTop: '0' }}>
            <Button variant="primary" className="btn-primary" onClick={this.handleYes}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
